/*
 * Config
 * @ Variables
 *
*/
/*calendar dropdown*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  YWW base Font size
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * H8
*/
/*
 * H9
*/
/*
 * H10
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
* form placeholder variables
*/
/*
 * Base
 * @ Colors
 *
*/
/* Colors */
/* Trip Advisor Color */
/* Guest checkout Color */
/*
 * Line height font
 */
/*
 * Border Radius
 */
/*
 * Border Radius Separate
 */
/*
 * Box Shadow
 */
/*
 * Widescreen
 */
/*
 * Desktop (PC's)
 */
/*
 * Wide screen Desktop (PC's)
 */
/*
 * Extra Wide screen Desktop (PC's)
 */
/*
 * Tablet
 */
/*
  * TabletOnly
  */
/*
 * upto Tablet
 */
/*
 * Tablet2
 */
/*
 * Mobile
 */
/*
 * No Screen
 */
/*
 * Transition
    usage example
      a{
      color: gray;
      @include transition(color .3s ease);
      &:hover {
        color: black;
      }
}
 */
/*
 * Opacity
 */
/*
 * Clearfix
 */
/*
 * Configure vendor
 */
/*
 * SVG stroke and fill
 */
/*
 * Transform
 */
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
 * Flex
 */
/*
 * New Design System - 2025
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
/*
 * Base
 * @ Gradients
 *
*/
/*
 * Line height font
 */
.c-header {
  position: relative;
  min-height: 54px;
  color: #404040;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  /* < 1023px */
}
.c-header.component {
  max-width: 1366px;
  display: block;
  margin: 0 auto;
}
@media only screen and (min-width: 1024px) {
  .c-header {
    height: 105px;
    padding: 15px 33px 14px 37px;
  }
}
.c-header .nav-item-search .overlay-content-container {
  overflow: visible;
}
.c-header .nav-item-search .overlay-content-container .overlay-content-inner-container {
  overflow: visible;
}
.c-header.sticky-header {
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1366px) {
  .c-header.sticky-header {
    position: fixed;
    top: 0;
    min-height: 55px;
    max-height: 55px;
    max-width: 100%;
    background: white;
  }
  .c-header.sticky-header .c-header-top-logo {
    width: 90px;
    margin-top: -25px;
  }
  .c-header.sticky-header .c-header-top-logo img {
    width: 90px;
  }
  .c-header.sticky-header .c-header-wrapper {
    background-color: #ffffff;
    max-width: 1366px;
    margin: 0 auto;
    padding-left: 33px;
    position: relative;
  }
  .c-header.sticky-header .header-body {
    margin-right: 15%;
    top: -7px;
  }
  .c-header.sticky-header .header-bottom {
    float: right;
    width: auto;
    top: -4px;
    max-height: 38px;
    right: 5px;
  }
  .c-header.sticky-header .header-bottom .c-header-top-icon,
  .c-header.sticky-header .header-bottom .opening-hours-dropdown, .c-header.sticky-header .header-bottom-profile, .c-header.sticky-header .header-bottom-lang-switcher {
    display: none;
  }
  .c-header.sticky-header .header-bottom .btn-primary {
    position: relative;
    bottom: 5px;
    right: 30px;
  }
  .c-header.sticky-header .nav-item-search .overlay-wrapper {
    top: -25px;
  }
}
@media (max-width: 768px) {
  .c-header .c-header-wrapper {
    background-color: #ffffff;
  }
}
.c-header .c-header-wrapper .btn-subscribe.sticky a {
  -webkit-border-top-left-radius: 18px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 18px;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 18px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 18px;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 18px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  display: inline-block;
  margin-bottom: 0;
  padding: 10px 30px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #0073cd;
  color: #ffffff;
  border: 2px solid #0073cd;
  /* Medium devices (desktops, 1024px and up) */
  border-radius: 0;
  padding: 15px 36px;
  font-size: 16px;
}
@media (min-width: 768px) {
  .c-header .c-header-wrapper .btn-subscribe.sticky a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header .c-header-wrapper .btn-subscribe.sticky a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-header .c-header-wrapper .btn-subscribe.sticky a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.c-header .c-header-wrapper .btn-subscribe.sticky a:focus {
  -webkit-border-top-left-radius: 18px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 18px;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 18px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 18px;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 18px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 0;
}
@media only screen and (min-width: 1024px) {
  .c-header .c-header-wrapper .btn-subscribe.sticky a:hover, .c-header .c-header-wrapper .btn-subscribe.sticky a:focus {
    background-color: #ffffff;
    color: #0073cd;
    border: 2px solid #0073cd;
  }
}
.c-header .c-header-wrapper .btn-subscribe.sticky a.disabled, .c-header .c-header-wrapper .btn-subscribe.sticky a[disabled], fieldset[disabled] .c-header .c-header-wrapper .btn-subscribe.sticky a {
  background-color: #a4a4a4;
  color: #ffffff;
  border: 2px solid #a4a4a4;
}
.c-header #c-header-top {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .c-header #c-header-top {
    padding: 10px 20px;
  }
  .c-header #c-header-top.ticket-upgrade-header {
    min-height: 61px;
  }
}
.c-header .c-header-top {
  min-height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-header .c-header-top {
    display: block;
    padding: 10px 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header .c-header-top {
    padding: 0;
    display: block;
  }
}
.c-header .c-header-top-logo {
  margin: 0;
  display: inline-block;
  width: 74px;
  height: auto;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-header .c-header-top-logo {
    margin: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header .c-header-top-logo {
    width: 118px;
    margin: 0 auto;
  }
}
.c-header .c-header-top-logo img {
  width: 100%;
  height: auto;
}
.c-header .c-header-top-icon {
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 22px;
  top: 50%;
  width: 24px;
  height: 21px;
  -webkit-transform: translateY(-50%);
  -khtml-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-header .c-header-top-icon {
    right: 22px;
    width: 29px;
    height: 25px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header .c-header-top-icon {
    right: 28px;
  }
}
.c-header .c-header-top-icon.hamburger {
  background-image: url("../../../assets/img/burger-icon.svg");
  background-position: center;
  right: 16px;
  height: 100%;
  width: auto;
}
.c-header .c-header-top-icon.hamburger img {
  display: none;
}
.c-header .c-header-top .close {
  border: 0;
  min-width: auto;
}
.c-header .c-header-top .profile {
  right: 60px;
}
.c-header .c-header-top .whatsapp {
  display: inline-block;
  margin-top: 6px;
  right: 94px;
  height: 30px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .c-header-top .whatsapp {
    display: none;
  }
}
.c-header.header-logged .c-header-top-icon.profile:after {
  /* Medium devices (desktops, 1024px and up) */
  position: absolute;
  content: "";
  border: 2px solid #0073cd;
  right: -1px;
  top: 6px;
  width: 5px;
  height: 5px;
  border-left: 0;
  border-top: 0;
  transform: rotate(45deg);
}
@media only screen and (min-width: 1024px) {
  .c-header.header-logged .c-header-top-icon.profile:after {
    display: none;
  }
}
.lang-ar .c-header.header-logged .c-header-top-icon.profile:after {
  left: -1px;
  right: auto;
}
.c-header.header-logged .logged-in-icon {
  display: block;
}
.c-header.header-logged .icn-user {
  display: none;
}
.c-header .header-bottom .search {
  display: none;
  height: 16px;
  width: 16px;
  left: 0;
  position: relative;
  margin-right: 20px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .header-bottom .search {
    display: block;
    margin-left: 0;
    margin-right: 20px;
  }
  .lang-ar .c-header .header-bottom .search {
    margin-left: 20px;
    margin-right: 0;
  }
}
.c-header .header-bottom .search use.search-icon {
  fill: #404040;
}
.c-header .header-bottom .search:hover use.search-icon {
  fill: #0073cd;
}
.c-header .header-bottom .header-bottom-profile {
  padding-bottom: 0;
}
.c-header .header-body {
  color: #404040;
  background-color: #f8f8f8;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .header-body {
    background-color: transparent;
    display: block;
    margin-top: 10px;
    width: auto;
  }
}
.c-header .header-body .autocomplete-wrapper .input-container {
  padding: 0 20px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-header .header-body .autocomplete-wrapper .input-container {
    padding: 60px 84px 0;
  }
}
.c-header .header-body .autocomplete-wrapper .input-container .form-input {
  border: 0;
  border-bottom: 1px solid #e0e0e0;
  color: #404040;
  caret-color: #e0e0e0;
  width: 100%;
  opacity: 1;
  padding-right: 35px;
  border-radius: 0;
}
.c-header .header-body .autocomplete-wrapper .input-container .btn-submit {
  right: 15px;
  margin: 0;
  border: 0;
  height: 25px;
  width: 25px;
  top: auto;
  padding: 0;
  min-width: auto;
  /* Small devices (tablets, 768px and up) */
}
.c-header .header-body .autocomplete-wrapper .input-container .btn-submit svg {
  height: 20px;
  width: 20px;
}
@media (min-width: 768px) {
  .c-header .header-body .autocomplete-wrapper .input-container .btn-submit {
    right: 80px;
  }
}
.c-header .header-body .autocomplete-wrapper .input-container .btn-submit:hover, .c-header .header-body .autocomplete-wrapper .input-container .btn-submit:active, .c-header .header-body .autocomplete-wrapper .input-container .btn-submit:focus {
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header .header-body .autocomplete-wrapper .input-container .btn-submit:hover, .c-header .header-body .autocomplete-wrapper .input-container .btn-submit:active, .c-header .header-body .autocomplete-wrapper .input-container .btn-submit:focus {
    background-color: transparent;
  }
}
.c-header .header-body .autocomplete-wrapper .results-container {
  margin: 25px 0;
}
.c-header .header-body .autocomplete-wrapper .results-container .autosuggest-list {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.71;
  letter-spacing: 0.7px;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  background: #f8f8f8;
  padding: 30px 75px 20px 84px;
  /* < 1023px */
  position: relative;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-header .header-body .autocomplete-wrapper .results-container .autosuggest-list {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header .header-body .autocomplete-wrapper .results-container .autosuggest-list {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-header .header-body .autocomplete-wrapper .results-container .autosuggest-list {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media (min-width: 320px) and (max-width: 1024px) {
  .c-header .header-body .autocomplete-wrapper .results-container .autosuggest-list {
    padding: 30px 30px 20px 30px;
  }
}
@media (min-width: 768px) {
  .c-header .header-body .autocomplete-wrapper .results-container .autosuggest-list {
    padding: 30px 84px 20px;
  }
}
.c-header .header-bottom {
  /* < 768px */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header .header-bottom {
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header .header-bottom {
    width: 42%;
  }
}
@media only screen and (min-width: 1366px) {
  .c-header .header-bottom {
    width: 25%;
  }
}
.c-header .header-bottom .cart {
  position: relative;
  top: auto;
  right: auto;
  align-self: flex-end;
  order: 2;
  margin: 21px 10px 3px 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .header-bottom .cart {
    top: 23px;
    right: auto;
    position: relative;
    align-self: auto;
    order: inherit;
    margin: 0;
    margin-left: 0;
    margin-right: 20px;
  }
  .lang-ar .c-header .header-bottom .cart {
    margin-left: 20px;
    margin-right: 0;
  }
}
.c-header .header-body {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .header-body {
    display: block;
    width: 100%;
    margin-top: 10px;
  }
}
.c-header .header-body .autocomplete-wrapper .input-container {
  padding: 0 20px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-header .header-body .autocomplete-wrapper .input-container {
    padding: 60px 84px 0;
  }
}
.c-header .header-body .autocomplete-wrapper .input-container .form-input {
  color: #404040;
  caret-color: #404040;
  opacity: 1;
}
.c-header .header-body .autocomplete-wrapper .input-container .form-input::placeholder {
  color: #404040;
}
.c-header .header-bottom {
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .header-bottom {
    width: 56%;
    display: flex;
  }
}
@media only screen and (min-width: 1366px) {
  .c-header .header-bottom {
    width: 36%;
  }
}
.c-header .header-bottom .search use.search-icon {
  fill: #404040;
}
.c-header .c-header-top-icon {
  height: 18px;
  width: 18px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .c-header-top-icon {
    right: auto;
    left: 0;
    margin-top: auto;
    position: relative;
    min-width: auto;
    margin-bottom: 8px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header .nav-item-search {
    position: absolute;
    left: auto;
    width: 36%;
    right: 25%;
    height: max-content;
    top: 18px;
    background: transparent;
  }
  .c-header .nav-item-search .overlay-wrapper {
    padding: 0;
    border: 1px solid #e0e0e0;
    border-radius: 0;
    background: transparent;
    color: #404040;
    left: -20%;
  }
  .c-header .nav-item-search .overlay-content-container {
    width: 100%;
  }
  .c-header .nav-item-search .overlay--close-button {
    height: 18px;
    top: 9px;
    right: 9px;
    padding-left: 12px;
    border-left: 1px solid #404040;
    border-radius: 0;
    z-index: 1;
    margin: 0;
  }
  .c-header .nav-item-search .overlay--close-button svg {
    width: 18px;
    height: 18px;
  }
  .c-header .nav-item-search .overlay--close-button .svg-use {
    fill: #404040;
    stroke: #404040;
  }
  .c-header .nav-item-search .autocomplete-wrapper .input-placeholder {
    display: none;
  }
  .c-header .nav-item-search .autocomplete-wrapper .input-container {
    background: #ffffff;
    padding: 5px;
  }
  .c-header .nav-item-search .autocomplete-wrapper .form-input {
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.71;
    letter-spacing: 0.7px;
    font-size: 14px;
    font-size: 0.875rem;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    /* Medium devices (desktops, 1366px and up) */
    color: #404040;
    caret-color: #404040;
    opacity: 1;
    padding-left: 14px;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 768px) {
  .c-header .nav-item-search .autocomplete-wrapper .form-input {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .c-header .nav-item-search .autocomplete-wrapper .form-input {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1366px) {
  .c-header .nav-item-search .autocomplete-wrapper .form-input {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header .nav-item-search .autocomplete-wrapper .btn-submit {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 38px;
    background: transparent;
    border: 0;
    padding: 0;
    color: #404040;
  }
  .c-header .nav-item-search .autocomplete-wrapper .btn-submit svg {
    height: 18px;
    width: 18px;
  }
  .c-header .nav-item-search .autocomplete-wrapper .autosuggest-list {
    margin-top: 14px;
    background: #f8f8f8;
    /* Medium devices (desktops, 1024px and up) */
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .c-header .nav-item-search .autocomplete-wrapper .autosuggest-list {
    padding: 30px 19px 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header .nav-item-search .autocomplete-wrapper .autosuggest-list:before {
    content: "";
    position: absolute;
    top: 3px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #f8f8f8;
  }
  .c-header .nav-item-search .brand-logo-container {
    display: none;
  }
}
@media only screen and (min-width: 1366px) {
  .c-header .nav-item-search {
    width: 36%;
  }
  .c-header .nav-item-search .overlay--close-button {
    height: 18px;
    top: 10px;
  }
  .c-header .nav-item-search .overlay--close-button svg {
    width: 18px;
    height: 18px;
  }
  .c-header .nav-item-search .autocomplete-wrapper .input-container {
    padding: 5px;
  }
  .c-header .nav-item-search .autocomplete-wrapper .btn-submit {
    width: 20px;
    height: 20px;
  }
  .c-header .nav-item-search .autocomplete-wrapper .btn-submit svg {
    height: 20px;
  }
}
.c-header.header-pre-logged .header-body, .c-header.header-logged .header-body {
  width: auto;
}
.c-header .custom-arrow-icon span {
  background-color: #404040;
}
.c-header.header-pre-logged .header-bottom, .c-header.header-logged .header-bottom {
  width: 100%;
}
.c-header .opening-hours-dropdown .opening-hours-container {
  color: #404040;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .opening-hours-dropdown .opening-hours-container {
    margin-bottom: 0;
    padding-top: 12px;
  }
}
.c-header .opening-hours-dropdown .opening-hours-container .opening-hours-header {
  margin-top: 1px;
}
.c-header .opening-hours-dropdown .opening-hours-container .opening-hours-header .opening-hours-header-left h4 {
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.5px;
}
.c-header .opening-hours-dropdown .opening-hours-container .opening-hours-header h4 {
  font-size: 14px;
  letter-spacing: 1.5px;
}
.c-header .opening-hours-dropdown .opening-hours-container .opening-hours-details .view-more {
  font-weight: 800;
  text-decoration: none;
  color: #0073cd;
  border-bottom: 2px solid #0073cd;
  margin-top: 10px;
  line-height: 18px;
  letter-spacing: 1px !important;
}
.lang-ar .c-header .opening-hours-dropdown .opening-hours-container .opening-hours-details .view-more {
  line-height: 20px;
  letter-spacing: 1.5px !important;
}
.c-header .opening-hours-dropdown.open .opening-hours-container {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header .opening-hours-dropdown.open .opening-hours-container {
    padding-top: 12px;
  }
}
@media (min-width: 320px) and (max-width: 1024px) {
  .custom-mobile-header .c-header .c-header-wrapper, .custom-mobile-header.sticky-header .c-header .c-header-wrapper {
    background-color: #ffffff;
  }
  .custom-mobile-header .c-header .c-header-wrapper .c-header-top .c-header-top-logo, .custom-mobile-header.sticky-header .c-header .c-header-wrapper .c-header-top .c-header-top-logo {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .custom-mobile-header .c-header .c-header-wrapper .c-header-top .c-header-top-logo a,
  .custom-mobile-header .c-header .c-header-wrapper .c-header-top .c-header-top-logo picture img, .custom-mobile-header.sticky-header .c-header .c-header-wrapper .c-header-top .c-header-top-logo a,
  .custom-mobile-header.sticky-header .c-header .c-header-wrapper .c-header-top .c-header-top-logo picture img {
    height: 100%;
    width: auto;
  }
  .custom-mobile-header .c-header .c-header-wrapper .c-header-top .btn-subscribe a, .custom-mobile-header.sticky-header .c-header .c-header-wrapper .c-header-top .btn-subscribe a {
    border: 0px;
  }
  .custom-mobile-header .c-header .c-header-wrapper.profile-open .header-bottom-btn-logout, .custom-mobile-header.sticky-header .c-header .c-header-wrapper.profile-open .header-bottom-btn-logout {
    margin: 0 60px;
    padding: 30px 0;
    width: auto;
  }
  .custom-mobile-header .c-header .c-header-wrapper.profile-open .header-bottom-btn-logout a, .custom-mobile-header.sticky-header .c-header .c-header-wrapper.profile-open .header-bottom-btn-logout a {
    color: #404040;
  }
  .custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .selected-option::after, .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .selected-option::after {
    right: -12px;
    left: auto;
  }
  .custom-mobile-header .c-header .c-header-wrapper .header-body .nav .nav-parent-title .header-nav-link, .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body .nav .nav-parent-title .header-nav-link {
    line-height: 16px;
  }
  .lang-ar .custom-mobile-header .c-header .c-header-wrapper .header-body .nav .nav-parent-title .header-nav-link, .lang-ar .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body .nav .nav-parent-title .header-nav-link {
    letter-spacing: 1.5px !important;
    line-height: 20px;
  }
  .custom-mobile-header .c-header .c-header-wrapper .header-body .nav .meganav .meganav--text-wrapper-list-item a, .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body .nav .meganav .meganav--text-wrapper-list-item a {
    letter-spacing: 0.8px;
    line-height: 16px;
  }
  .lang-ar .custom-mobile-header .c-header .c-header-wrapper .header-body .nav .meganav .meganav--text-wrapper-list-item a, .lang-ar .custom-mobile-header.sticky-header .c-header .c-header-wrapper .header-body .nav .meganav .meganav--text-wrapper-list-item a {
    line-height: 20px;
    letter-spacing: 1.5px !important;
  }
}

#hamburger-cross-icon span {
  height: 2.2px;
  background: #404040;
}

/* < 1023px */
@media (min-width: 320px) and (max-width: 1024px) {
  .custom-mobile-header,
  .custom-mobile-header:not(.mobile-menu-opened).sticky-header {
    position: sticky;
    top: 0;
  }
}
.header-email-verification-parent .hamburger {
  pointer-events: none !important;
  cursor: default !important;
}
.header-email-verification-parent .c-header {
  display: flex !important;
}
.header-email-verification-parent .c-header-wrapper {
  display: flex !important;
  justify-content: center !important;
}
.header-email-verification-parent .c-header-top {
  display: flex !important;
}
.header-email-verification-parent .header-bottom {
  display: none !important;
}

.mypayment--page .c-header-top-icon-wrap {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .mypayment--page .c-header-top-icon-wrap {
    display: none;
  }
}
.mypayment--page .c-header-wrapper .c-header-top .c-header-top-icon.cart span,
.mypayment--page .c-header-wrapper .c-header-top .c-header-top-icon.cart svg,
.mypayment--page .c-header-wrapper .c-header-top .c-header-top-icon.cart p {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .mypayment--page .c-header-wrapper .c-header-top .c-header-top-icon.cart span,
  .mypayment--page .c-header-wrapper .c-header-top .c-header-top-icon.cart svg,
  .mypayment--page .c-header-wrapper .c-header-top .c-header-top-icon.cart p {
    display: none;
  }
}

.custom-mini-header .c-mini-header {
  background: #1C5EA2;
}
.custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .custom-mini-header .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip ul li:last-child {
    border-bottom: 0.5px solid #e0e0e0;
  }
}

.custom-mobile-header .c-header .c-header-wrapper .header-bottom-lang-switcher,
.custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher,
.c-mini-header .container .right-container .header-bottom-lang-switcher {
  margin-top: 0;
}
.custom-mobile-header .c-header .c-header-wrapper .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option span,
.custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option span,
.c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option span {
  font-weight: 800;
}
.lang-ar .custom-mobile-header .c-header .c-header-wrapper .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option span,
.lang-ar .custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option span,
.lang-ar .c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option span {
  font-family: "Open Sans", Arial, sans-serif !important;
}
.custom-mobile-header .c-header .c-header-wrapper .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option::after,
.custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option::after,
.c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option::after {
  transform: translateY(-50%) rotate(315deg);
  top: calc(50% + 2px);
  bottom: auto;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .custom-mobile-header .c-header .c-header-wrapper .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option::after,
  .custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option::after,
  .c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option::after {
    right: 0;
  }
  .lang-ar .custom-mobile-header .c-header .c-header-wrapper .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option::after,
  .lang-ar .custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option::after,
  .lang-ar .c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option::after {
    right: auto;
    left: 0;
  }
}
.lang-ar .custom-mobile-header .c-header .c-header-wrapper .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option::after,
.lang-ar .custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option::after,
.lang-ar .c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option::after {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .lang-ar .custom-mobile-header .c-header .c-header-wrapper .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option::after,
  .lang-ar .custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option::after,
  .lang-ar .c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option::after {
    right: auto;
    left: -12px;
  }
}
.custom-mobile-header .c-header .c-header-wrapper .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option.collapse::after,
.custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option.collapse::after,
.c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .selected-option.collapse::after {
  top: calc(50% - 2px);
  transform: translateY(-50%) rotate(135deg);
  bottom: auto;
}
.custom-mobile-header .c-header .c-header-wrapper .header-bottom-lang-switcher .language-switcher .dropdown-component .dropdown-menu,
.custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .language-switcher .dropdown-component .dropdown-menu,
.c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .dropdown-menu {
  border: 1px solid #e0e0e0;
  bottom: auto;
}
.custom-mobile-header .c-header .c-header-wrapper .header-bottom-lang-switcher .language-switcher .dropdown-component .dropdown-menu::before,
.custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .language-switcher .dropdown-component .dropdown-menu::before,
.c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .dropdown-menu::before {
  bottom: auto;
}
.custom-mobile-header .c-header .c-header-wrapper .header-bottom-lang-switcher .language-switcher .dropdown-component .dropdown-menu li:last-child,
.custom-mobile-header .c-header .c-header-wrapper .header-body .top-body-sec .header-bottom-lang-switcher .language-switcher .dropdown-component .dropdown-menu li:last-child,
.c-mini-header .container .right-container .header-bottom-lang-switcher .language-switcher .dropdown-component .dropdown-menu li:last-child {
  border-top: 0.5px solid #e0e0e0;
}

.header-bottom-profile-tooltip {
  padding: 30px 20px 0px;
}
.header-bottom-profile-tooltip .header-bottom-profile-name {
  font-weight: 700 !important;
}
.header-bottom-profile-tooltip .header-bottom-btn-logout {
  border-bottom: none !important;
}

.mypayment--page .page-container .grey-bg .row .col-lg-8.col-md-12.col-sm-12,
.booking--page .page-container .grey-bg .row .col-lg-8.col-md-12.col-sm-12 {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .mypayment--page .page-container .grey-bg .row .col-lg-8.col-md-12.col-sm-12,
  .booking--page .page-container .grey-bg .row .col-lg-8.col-md-12.col-sm-12 {
    padding-top: 40px;
  }
}
.mypayment--page .page-container .grey-bg .row .col-lg-4.col-md-12.col-sm-12 .cart-summary-editable .c-my-cart-b2c-container .c-b2c-cart .c-b2c-cart-wrapper,
.booking--page .page-container .grey-bg .row .col-lg-4.col-md-12.col-sm-12 .cart-summary-editable .c-my-cart-b2c-container .c-b2c-cart .c-b2c-cart-wrapper {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .mypayment--page .page-container .grey-bg .row .col-lg-4.col-md-12.col-sm-12 .cart-summary-editable .c-my-cart-b2c-container .c-b2c-cart .c-b2c-cart-wrapper,
  .booking--page .page-container .grey-bg .row .col-lg-4.col-md-12.col-sm-12 .cart-summary-editable .c-my-cart-b2c-container .c-b2c-cart .c-b2c-cart-wrapper {
    margin-top: 40px;
  }
}
.mypayment--page .page-container .grey-bg .row .col-lg-4.col-md-12.col-sm-12 .cart-summary-editable .c-my-cart-b2c-container .c-b2c-cart .c-b2c-cart-wrapper .cart-payment-empty .heading-error,
.booking--page .page-container .grey-bg .row .col-lg-4.col-md-12.col-sm-12 .cart-summary-editable .c-my-cart-b2c-container .c-b2c-cart .c-b2c-cart-wrapper .cart-payment-empty .heading-error {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .mypayment--page .page-container .grey-bg .row .col-lg-4.col-md-12.col-sm-12 .cart-summary-editable .c-my-cart-b2c-container .c-b2c-cart .c-b2c-cart-wrapper .cart-payment-empty .heading-error,
  .booking--page .page-container .grey-bg .row .col-lg-4.col-md-12.col-sm-12 .cart-summary-editable .c-my-cart-b2c-container .c-b2c-cart .c-b2c-cart-wrapper .cart-payment-empty .heading-error {
    text-align: center;
  }
}
.mypayment--page .page-container .grey-bg .row .col-lg-4.col-md-12.col-sm-12 .cart-summary-editable .c-my-cart-b2c-container .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container.empty-cart-container-payment,
.booking--page .page-container .grey-bg .row .col-lg-4.col-md-12.col-sm-12 .cart-summary-editable .c-my-cart-b2c-container .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container.empty-cart-container-payment {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .mypayment--page .page-container .grey-bg .row .col-lg-4.col-md-12.col-sm-12 .cart-summary-editable .c-my-cart-b2c-container .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container.empty-cart-container-payment,
  .booking--page .page-container .grey-bg .row .col-lg-4.col-md-12.col-sm-12 .cart-summary-editable .c-my-cart-b2c-container .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container.empty-cart-container-payment {
    align-items: center;
  }
}
.mypayment--page .page-container .grey-bg .row .custom-payfort-form .c-form .vertical-card-number,
.booking--page .page-container .grey-bg .row .custom-payfort-form .c-form .vertical-card-number {
  margin-bottom: 20px !important;
}
.mypayment--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-header .cart-title,
.booking--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-header .cart-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 18px !important;
  line-height: 22px !important;
}
.mypayment--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-header .c-b2c-cart-header-container .grid-check-icon .chevronDown #grid-check-icon-cart-summary::after,
.booking--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-header .c-b2c-cart-header-container .grid-check-icon .chevronDown #grid-check-icon-cart-summary::after {
  /* Medium devices (desktops, 1024px and up) */
  font-size: 14px;
  border-width: 3px;
}
@media only screen and (min-width: 1024px) {
  .mypayment--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-header .c-b2c-cart-header-container .grid-check-icon .chevronDown #grid-check-icon-cart-summary::after,
  .booking--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-header .c-b2c-cart-header-container .grid-check-icon .chevronDown #grid-check-icon-cart-summary::after {
    color: #353535 !important;
  }
}
.mypayment--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .cart-summary .cart-summary-details .total-price-container,
.booking--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .cart-summary .cart-summary-details .total-price-container {
  display: flex;
  flex-direction: column;
}
.mypayment--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .cart-summary .cart-summary-details .total-price-container .coupon-discount-container,
.booking--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .cart-summary .cart-summary-details .total-price-container .coupon-discount-container {
  order: 2;
}
.mypayment--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .cart-summary .cart-summary-details .total-price-container .currency-dropdown-container,
.booking--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .cart-summary .cart-summary-details .total-price-container .currency-dropdown-container {
  order: 1;
}
.mypayment--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .cart-summary .cart-summary-details .total-price-container .currency-price-container,
.booking--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .cart-summary .cart-summary-details .total-price-container .currency-price-container {
  order: 3;
}
.mypayment--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .ticket-subsection-container .ticket-sub-section .ticket-info .delete-btn,
.booking--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .ticket-subsection-container .ticket-sub-section .ticket-info .delete-btn {
  padding-left: 8px !important;
}
html[lang=ar-AE] .mypayment--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .ticket-subsection-container .ticket-sub-section .ticket-info .delete-btn,
html[lang=ar-AE] .booking--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .ticket-subsection-container .ticket-sub-section .ticket-info .delete-btn {
  padding-right: 10px;
  padding-left: 0;
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  html[lang=ar-AE] .mypayment--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .ticket-subsection-container .ticket-sub-section .ticket-info .delete-btn,
  html[lang=ar-AE] .booking--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .ticket-subsection-container .ticket-sub-section .ticket-info .delete-btn {
    padding-left: 10px !important;
  }
}
.mypayment--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-wrapper .c-coupon-code .c-coupon-code-header .c-coupon-code-payment--add-icon .icon-applied::after,
.booking--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-wrapper .c-coupon-code .c-coupon-code-header .c-coupon-code-payment--add-icon .icon-applied::after {
  height: 18px !important;
  width: 18px !important;
  top: 18px !important;
}
.mypayment--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-wrapper .c-coupon-code .c-coupon-code-wrapper .c-coupon-code-payment--add-icon .icon-applied::after,
.booking--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-wrapper .c-coupon-code .c-coupon-code-wrapper .c-coupon-code-payment--add-icon .icon-applied::after {
  height: 18px !important;
  width: 18px !important;
  top: 2px !important;
  left: -2px !important;
}
.mypayment--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-wrapper .c-coupon-code .c-coupon-code-wrapper .c-coupon-code-section .c-coupon-code-wrapper--remove, .mypayment--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-wrapper .c-coupon-code .c-coupon-code-wrapper .c-coupon-code-section .c-coupon-code-wrapper--add,
.booking--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-wrapper .c-coupon-code .c-coupon-code-wrapper .c-coupon-code-section .c-coupon-code-wrapper--remove,
.booking--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-wrapper .c-coupon-code .c-coupon-code-wrapper .c-coupon-code-section .c-coupon-code-wrapper--add {
  border-left: 1px solid #d3d3d3 !important;
}
html[lang=ar-AE] .mypayment--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-wrapper .c-coupon-code .c-coupon-code-wrapper .c-coupon-code-section .c-coupon-code-wrapper--remove, html[lang=ar-AE] .mypayment--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-wrapper .c-coupon-code .c-coupon-code-wrapper .c-coupon-code-section .c-coupon-code-wrapper--add,
html[lang=ar-AE] .booking--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-wrapper .c-coupon-code .c-coupon-code-wrapper .c-coupon-code-section .c-coupon-code-wrapper--remove,
html[lang=ar-AE] .booking--page .page-container .grey-bg .c-my-cart-b2c .c-my-cart-b2c-container .c-b2c-cart-wrapper .c-coupon-code .c-coupon-code-wrapper .c-coupon-code-section .c-coupon-code-wrapper--add {
  border-right: 1px solid #d3d3d3 !important;
}
html[lang=ar-AE] .mypayment--page .page-container .grey-bg .guest-checkout-wrap .c-form-wrapper .email-control-wrapper-2 .form-element.select-with-text input,
html[lang=ar-AE] .booking--page .page-container .grey-bg .guest-checkout-wrap .c-form-wrapper .email-control-wrapper-2 .form-element.select-with-text input {
  margin-right: 0 !important;
}
.mypayment--page .page-container .grey-bg .guest-checkout-wrap .form-checkbox #error_TermsAndConditions,
.booking--page .page-container .grey-bg .guest-checkout-wrap .form-checkbox #error_TermsAndConditions {
  margin-top: -5px;
}
.mypayment--page .page-container .grey-bg .guest-checkout-wrapper-container .main-title,
.booking--page .page-container .grey-bg .guest-checkout-wrapper-container .main-title {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .mypayment--page .page-container .grey-bg .guest-checkout-wrapper-container .main-title,
  .booking--page .page-container .grey-bg .guest-checkout-wrapper-container .main-title {
    margin: 30px 0px 0px !important;
  }
}
.mypayment--page .page-container .grey-bg .c-payment.c-payment-vertical .c-payment-wrapper,
.booking--page .page-container .grey-bg .c-payment.c-payment-vertical .c-payment-wrapper {
  padding: 0px !important;
}
.mypayment--page .page-container .grey-bg .c-payment.c-payment-vertical .c-payment-wrapper .sub-title,
.booking--page .page-container .grey-bg .c-payment.c-payment-vertical .c-payment-wrapper .sub-title {
  margin-top: 40px !important;
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .mypayment--page .page-container .grey-bg .c-payment.c-payment-vertical .c-payment-wrapper .sub-title,
  .booking--page .page-container .grey-bg .c-payment.c-payment-vertical .c-payment-wrapper .sub-title {
    margin-top: 30px !important;
  }
}
.mypayment--page .page-container .grey-bg .c-payment.c-payment-vertical .c-payment-wrapper .payment-error,
.booking--page .page-container .grey-bg .c-payment.c-payment-vertical .c-payment-wrapper .payment-error {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .mypayment--page .page-container .grey-bg .c-payment.c-payment-vertical .c-payment-wrapper .payment-error,
  .booking--page .page-container .grey-bg .c-payment.c-payment-vertical .c-payment-wrapper .payment-error {
    width: 100% !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.mypayment--page .page-container .grey-bg .c-payment.c-payment-vertical .c-payment-wrapper .payment-error .close-icon-2step,
.booking--page .page-container .grey-bg .c-payment.c-payment-vertical .c-payment-wrapper .payment-error .close-icon-2step {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .mypayment--page .page-container .grey-bg .c-payment.c-payment-vertical .c-payment-wrapper .payment-error .close-icon-2step,
  .booking--page .page-container .grey-bg .c-payment.c-payment-vertical .c-payment-wrapper .payment-error .close-icon-2step {
    background-position: unset !important;
  }
}
.mypayment--page .page-container .grey-bg .c-payment-wrapper .payment-container-wrapper .right-section .c-payfort .custom-payfort-form .c-payfort-form-v1 .payfort-submit-btn .text-center .payment-submit-btn .btn-icon,
.booking--page .page-container .grey-bg .c-payment-wrapper .payment-container-wrapper .right-section .c-payfort .custom-payfort-form .c-payfort-form-v1 .payfort-submit-btn .text-center .payment-submit-btn .btn-icon {
  display: inline-block;
}
.mypayment--page .page-container .grey-bg .annualPassContainer,
.booking--page .page-container .grey-bg .annualPassContainer {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .mypayment--page .page-container .grey-bg .annualPassContainer,
  .booking--page .page-container .grey-bg .annualPassContainer {
    padding-top: 0px !important;
  }
}
.mypayment--page .page-container .grey-bg .annualPassContainer .c-annual-pass .c-form .heading-3,
.booking--page .page-container .grey-bg .annualPassContainer .c-annual-pass .c-form .heading-3 {
  line-height: 44px !important;
  font-size: 40px !important;
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .mypayment--page .page-container .grey-bg .annualPassContainer .c-annual-pass .c-form .heading-3,
  .booking--page .page-container .grey-bg .annualPassContainer .c-annual-pass .c-form .heading-3 {
    font-size: 32px !important;
  }
}
.mypayment--page .page-container .grey-bg .annualPassContainer .c-annual-pass .guest-detail,
.booking--page .page-container .grey-bg .annualPassContainer .c-annual-pass .guest-detail {
  padding: 15px 0 0 !important;
}
html[lang=ar-AE] .mypayment--page .c-mini-header .container,
html[lang=ar-AE] .booking--page .c-mini-header .container {
  padding: 0 22px;
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  html[lang=ar-AE] .mypayment--page .c-mini-header .container,
  html[lang=ar-AE] .booking--page .c-mini-header .container {
    padding: 0;
  }
}
.mypayment--page .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip,
.booking--page .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip {
  padding: 15px 20px 0;
}
.mypayment--page .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip .header-bottom-profile-name,
.booking--page .c-mini-header .container .right-container .header-bottom-profile .header-bottom-profile-tooltip .header-bottom-profile-name {
  font-weight: 700;
}
.mypayment--page .header-bottom-profile-tooltip,
.booking--page .header-bottom-profile-tooltip {
  padding: 15px 20px 0;
}
.mypayment--page .header-bottom-profile-tooltip .header-bottom-profile-name,
.booking--page .header-bottom-profile-tooltip .header-bottom-profile-name {
  font-weight: 700;
}
.mypayment--page .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-header .cart-title,
.booking--page .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-header .cart-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 18px !important;
  line-height: 22px !important;
}
.mypayment--page .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary,
.booking--page .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .mypayment--page .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary,
  .booking--page .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary {
    gap: 5px;
  }
}
.mypayment--page .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .cart-price-container .total-price-container .currency-price-container .final-price-container .cart-total-label,
.booking--page .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .cart-price-container .total-price-container .currency-price-container .final-price-container .cart-total-label {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .mypayment--page .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .cart-price-container .total-price-container .currency-price-container .final-price-container .cart-total-label,
  .booking--page .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .cart-price-container .total-price-container .currency-price-container .final-price-container .cart-total-label {
    font-weight: 700;
  }
}
.mypayment--page .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .ticket-sub-section .ticket-item .ticket-type .price,
.booking--page .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .ticket-sub-section .ticket-item .ticket-type .price {
  letter-spacing: normal !important;
}
.mypayment--page .c-my-cart-b2c-container .c-b2c-cart-wrapper .c-b2c-cart-content .ticket-section .ticket-subsection-container .ticket-sub-section .ticket-type .price,
.booking--page .c-my-cart-b2c-container .c-b2c-cart-wrapper .c-b2c-cart-content .ticket-section .ticket-subsection-container .ticket-sub-section .ticket-type .price {
  letter-spacing: normal !important;
}

.tenant-yww .header-miniCart .ticket-subsection-container .ticket-info .delete-btn {
  margin-top: 5px;
  margin-right: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: 0;
}
.tenant-yww .c-b2c-cart-content .ticket-section .ticket-info .delete-btn {
  padding: 0;
}
.tenant-yww .c-b2c-cart-content .ticket-section .ticket-info .delete-btn svg {
  width: 30px !important;
  height: 30px !important;
}

.language-switcher .dropdown-component .selected-option {
  border: 0;
  color: #404040;
  padding-right: 10px;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 0.8px;
  line-height: 1.33;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .language-switcher .dropdown-component .selected-option {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .language-switcher .dropdown-component .selected-option {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .language-switcher .dropdown-component .selected-option {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.language-switcher .dropdown-component .selected-option:after {
  /* < 768px */
  top: 6px;
  width: 6.36px;
  height: 6.36px;
  right: 3px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .language-switcher .dropdown-component .selected-option:after {
    right: 2px;
  }
}
.language-switcher .dropdown-component .selected-option.collapse:after {
  top: 4px;
}
.language-switcher .dropdown-menu {
  background-color: #635c5c;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  right: -8px;
  top: auto;
  border-radius: 4px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .language-switcher .dropdown-menu {
    top: 42px;
  }
}
.language-switcher .dropdown-menu li + li {
  border-top: solid 1px #d3d0d0;
}
.language-switcher .dropdown-menu:before {
  border-bottom-color: #e0e0e0;
}
.language-switcher .dropdown-item {
  padding: 16px 18px;
  letter-spacing: 1.8px;
  color: #404040;
  background: #e0e0e0;
  outline: 0;
}
.language-switcher .dropdown-item > div {
  padding: 0;
  border-top: 0;
}
.language-switcher .dropdown-item:hover, .language-switcher .dropdown-item:focus-within {
  background-color: #a4a4a4;
}
.language-switcher .dropdown-item:hover a, .language-switcher .dropdown-item:focus-within a {
  color: #ffffff;
}
.language-switcher .dropdown-item.focus-within {
  background-color: #a4a4a4;
}
.language-switcher .dropdown-item.focus-within a {
  color: #ffffff;
}
.language-switcher .dropdown-item a {
  padding-top: 4px;
}
.lang-en .language-switcher .dropdown-item a {
  font-weight: 400;
}

.header-bottom {
  display: flex;
  position: relative;
  color: #ffffff;
  padding: 15px 20px;
  padding-top: 0;
  flex-direction: row;
  min-height: auto;
  background-color: #f8f8f8;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom {
    background-color: transparent;
    display: inherit;
    justify-content: flex-end;
    flex-direction: row;
    margin-left: auto;
    height: 45px;
    min-height: auto;
    padding: 0;
  }
}
.header-bottom-lang-switcher {
  margin-right: 0;
  align-self: center;
  margin-left: 0;
  order: 3;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom-lang-switcher {
    margin-top: 2px;
    margin-bottom: 0;
    margin-right: 20px;
    margin-left: 20px;
    order: inherit;
  }
}
.header-bottom-lang-switcher .dropdown-component .selected-option {
  padding-left: 15px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom-lang-switcher .dropdown-component .selected-option {
    padding-left: 0;
  }
}
.header-bottom-lang-switcher .dropdown-component .selected-option:before {
  left: 0;
  right: auto;
  border: 1px solid #e0e0e0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom-lang-switcher .dropdown-component .selected-option:before {
    left: auto;
    right: auto;
    border: none;
  }
}
.header-bottom-btn-book-tickets {
  background: transparent;
  border-color: #ffffff;
}
.header-bottom-btn-book-tickets:hover a {
  color: #a4a4a4;
}
.header-bottom-btn-book-tickets a {
  color: #ffffff;
}
.header-bottom-btn-login {
  background: transparent;
}
.header-bottom-btn-login:hover a {
  color: #a4a4a4;
}
.header-bottom-btn-login a {
  color: #ffffff;
}
.header-bottom-btn-logout {
  background: transparent;
  text-align: left;
  padding: 20px 40px 120px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom-btn-logout {
    padding: 15px 0;
    border-bottom: 1px solid #e0e0e0;
  }
}
.header-bottom-btn-logout a {
  color: #404040;
  margin: 0;
  border-radius: inherit;
  border: 0;
  padding: 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.67;
  letter-spacing: 0.6px;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .header-bottom-btn-logout a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .header-bottom-btn-logout a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .header-bottom-btn-logout a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .header-bottom-btn-logout a {
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
  }
}
.header-bottom-btn-logout a:hover, .header-bottom-btn-logout a:focus {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom-btn-logout a:hover, .header-bottom-btn-logout a:focus {
    background-color: transparent;
  }
}
.header-bottom-profile {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom-profile {
    margin: 0;
  }
}
.header-bottom-profile .header-bottom-profile-tooltip {
  display: none;
}
.header-bottom-profile-tooltip {
  background-color: #ffffff;
  color: #404040;
  /* Medium devices (desktops, 1024px and up) */
}
.header-bottom-profile-tooltip a {
  color: #404040;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom-profile-tooltip a {
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    display: block;
  }
}
@media only screen and (min-width: 1024px) {
  .header-bottom-profile-tooltip {
    top: 45px;
    right: -13px;
    width: 240px;
    padding: 30px 20px 5px;
    background-color: #f8f8f8;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
    border: 0;
    border-radius: 0;
  }
  .header-bottom-profile-tooltip .header-bottom-profile-name {
    padding: 0;
    padding-bottom: 5px;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.67;
    letter-spacing: 0.6px;
    font-size: 12px;
    font-size: 0.75rem;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    /* Medium devices (desktops, 1366px and up) */
    color: #404040;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 768px) {
  .header-bottom-profile-tooltip .header-bottom-profile-name {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .header-bottom-profile-tooltip .header-bottom-profile-name {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1366px) {
  .header-bottom-profile-tooltip .header-bottom-profile-name {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .header-bottom-profile-tooltip ul li {
    padding: 15px 0;
    border-top: 0;
    border-bottom: 1px solid #e0e0e0;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    line-height: 1.67;
    letter-spacing: 0.6px;
    font-size: 12px;
    font-size: 0.75rem;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    /* Medium devices (desktops, 1366px and up) */
  }
}
@media only screen and (min-width: 1024px) and (min-width: 768px) {
  .header-bottom-profile-tooltip ul li {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .header-bottom-profile-tooltip ul li {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1366px) {
  .header-bottom-profile-tooltip ul li {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .header-bottom-profile-tooltip ul li:hover {
    background-color: transparent;
  }
  .header-bottom-profile-tooltip ul li:last-child {
    border-bottom: 1px solid #e0e0e0;
  }
}
.header-bottom-profile-tooltip:before {
  border-bottom-color: #f8f8f8;
  right: 10.5%;
}
.header-bottom .profile {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom .profile {
    display: block;
    box-sizing: content-box;
    padding-left: 0;
  }
}
.header-bottom .profile.logged-in {
  padding-right: 12px;
}
.header-bottom .profile.logged-in .icn-user {
  display: none;
}
.header-bottom .profile.logged-in .logged-in-icon {
  display: block;
}
.header-bottom .profile.logged-in span:after {
  border: 2px solid #0073cd;
  left: 7px;
  top: 6px;
  width: 5px;
  height: 5px;
  border-left: 0;
  border-bottom: 0;
  content: "";
  transform: translate(15px) rotate(135deg);
}
.header-bottom .btn.btn-secondary a {
  background: transparent;
  color: #ffffff;
}
.header-bottom .btn.btn-secondary a:focus, .header-bottom .btn.btn-secondary a:hover {
  background: #ffffff;
  color: #333;
}
.header-bottom .search,
.header-bottom .cart {
  border: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom .search,
  .header-bottom .cart {
    float: left;
    margin: 0;
    margin-right: 20px;
    min-width: auto;
  }
}
.header-bottom .btn-primary {
  margin-left: 0;
  order: 1;
  flex: 1;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom .btn-primary {
    margin-left: 0;
    order: inherit;
    flex: inherit;
  }
  .header-bottom .btn-primary.btn-subscribe {
    height: max-content;
  }
}
.header-bottom .btn-primary a {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #404040;
}
@media (min-width: 768px) {
  .header-bottom .btn-primary a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .header-bottom .btn-primary a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .header-bottom .btn-primary a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.lang-ar .header-bottom .btn-primary a {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.43;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .header-bottom .btn-primary a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom .btn-primary a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .header-bottom .btn-primary a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.header-bottom .btn-primary a:before {
  background: #ffffff;
}
.header-bottom a:focus {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-bottom a:focus {
    box-sizing: content-box;
  }
}

.cart {
  right: 124px;
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .cart {
  right: 0;
}
.custom-mobile-header .cart {
  border: 0px;
}
@media only screen and (min-width: 1024px) {
  .cart {
    top: 0;
  }
}
.cart .total-quantity {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 0.8px;
  line-height: 1.33;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  letter-spacing: 1px;
  line-height: 18px;
  border-radius: 50%;
  min-width: 23px;
  min-height: 23px;
  position: absolute;
  text-align: center;
  color: #ffffff;
  background: #0073cd;
  left: 12px;
  top: -7px;
  padding: 2px 2px;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .cart .total-quantity {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .cart .total-quantity {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .cart .total-quantity {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .cart .total-quantity {
    left: 8px;
    top: -11px;
  }
}

.c-mini-header .container:has(.logged-in) {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-mini-header .container:has(.logged-in) {
    padding: 0 22px 0 22px;
  }
}
.c-mini-header .header-bottom-profile .header-bottom-profile-tooltip .header-bottom-profile-name {
  margin-bottom: 5px !important;
}
.c-mini-header .header-bottom-profile .header-bottom-profile-name {
  margin-bottom: 5px;
}
.c-mini-header .header-bottom-profile .profile {
  align-items: center;
  display: flex;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-mini-header .header-bottom-profile .profile {
    box-sizing: content-box;
    padding-left: 0;
    position: relative;
  }
}
.c-mini-header .header-bottom-profile .profile.logged-in {
  padding-right: 12px;
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .lang-ar .c-mini-header .header-bottom-profile .profile.logged-in {
    padding-left: 0;
    padding-right: 2px;
    left: 0;
  }
}
.c-mini-header .header-bottom-profile .profile.logged-in .icn-user {
  display: none;
}
.c-mini-header .header-bottom-profile .profile.logged-in .logged-in-icon {
  display: block;
}
.c-mini-header .header-bottom-profile .profile.logged-in span:after {
  border: 2px solid #ffffff;
  left: 12px;
  top: 50%;
  width: 5px;
  height: 5px;
  border-left: 0;
  border-bottom: 0;
  content: "";
  transform: translate(15px, -50%) rotate(135deg);
  position: absolute;
}
.lang-ar .c-mini-header .header-bottom-profile .profile.logged-in span:after {
  left: -19px;
}
.c-mini-header .header-bottom-profile .profile.logged-in span svg {
  width: 18px;
  height: 18px;
}

.header-bottom-profile-tooltip::before {
  right: 2.5%;
}
.lang-ar .header-bottom-profile-tooltip::before {
  right: 10.5%;
}

/*
 * Config
 * @ Variables
 *
*/
/*calendar dropdown*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  YWW base Font size
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * H8
*/
/*
 * H9
*/
/*
 * H10
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
* form placeholder variables
*/
/*
 * Base
 * @ Colors
 *
*/
/* Colors */
/* Trip Advisor Color */
/* Guest checkout Color */
/*
 * Line height font
 */
/*
 * Border Radius
 */
/*
 * Border Radius Separate
 */
/*
 * Box Shadow
 */
/*
 * Widescreen
 */
/*
 * Desktop (PC's)
 */
/*
 * Wide screen Desktop (PC's)
 */
/*
 * Extra Wide screen Desktop (PC's)
 */
/*
 * Tablet
 */
/*
  * TabletOnly
  */
/*
 * upto Tablet
 */
/*
 * Tablet2
 */
/*
 * Mobile
 */
/*
 * No Screen
 */
/*
 * Transition
    usage example
      a{
      color: gray;
      @include transition(color .3s ease);
      &:hover {
        color: black;
      }
}
 */
/*
 * Opacity
 */
/*
 * Clearfix
 */
/*
 * Configure vendor
 */
/*
 * SVG stroke and fill
 */
/*
 * Transform
 */
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
 * Flex
 */
/*
 * New Design System - 2025
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
/*
 * Base
 * @ Gradients
 *
*/
/*
 * Line height font
 */
.header-body {
  color: #ffffff;
  border: 0;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-body {
    display: block;
    background-color: transparent;
    border-bottom: 0;
    position: absolute;
    top: 64%;
    width: 100%;
    left: auto;
    right: 0;
    z-index: 1;
  }
}
@media only screen and (min-width: 1366px) {
  .header-body {
    top: 60%;
  }
}
.header-body a {
  color: #ffffff;
}
.header-body-content-logged-search-bar {
  background-color: transparent;
}
.header-body-rte {
  color: #ffffff;
}
.header-body-profile ul {
  text-align: left;
  margin: 0 60px;
  padding: 0;
}
.header-body-profile ul li {
  border-bottom: 1px solid #e0e0e0;
  line-height: 0;
  padding: 30px 0px;
}
.header-body-profile ul li a {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.67;
  letter-spacing: 0.6px;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #404040;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
}
@media (min-width: 768px) {
  .header-body-profile ul li a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .header-body-profile ul li a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .header-body-profile ul li a {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.header-body-profile-name {
  border: 0;
  text-align: left;
  margin: 0 60px;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.67;
  letter-spacing: 0.6px;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  padding-top: 120px;
  min-height: auto;
}
@media (min-width: 768px) {
  .header-body-profile-name {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .header-body-profile-name {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .header-body-profile-name {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.header-body-btn-register {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-body-btn-register {
    position: absolute;
    bottom: 38px;
    width: auto;
    right: 348px;
  }
}
.header-body-btn-register a {
  background: transparent;
  color: white;
  border-color: white;
}
.header-body-nav-links {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .header-body-nav-links {
    float: right;
    width: auto;
  }
}
.header-body-content-logged {
  display: block;
}
.header-body-content-logged nav {
  margin: 26px 44px 0;
  padding-bottom: 20px;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .header-body-content-logged nav {
    margin: 26 80px;
  }
}
.header-body .autocomplete-wrapper .input-container {
  padding: 16px;
  background-color: transparent;
}
.header-body .autocomplete-wrapper .input-container .input-placeholder {
  display: none;
}
.header-body .autocomplete-wrapper .input-container ::-webkit-input-placeholder {
  color: #ffffff;
}
.header-body .autocomplete-wrapper .input-container .form-input {
  border: 0;
  border-bottom: 1px solid #ffffff;
  padding-top: 18px;
  padding-bottom: 16px;
  color: #ffffff;
  caret-color: #ffffff;
  margin-right: 0;
}
.header-body .autocomplete-wrapper .input-container .btn-submit {
  position: absolute;
  right: 28px;
  top: 16px;
}
.header-body .nav--wrapper-content.nav--wrapper- {
  margin-right: 0;
}
.header-body .nav--wrapper-content.nav--wrapper-.has-submenu {
  margin-right: 5px;
}

.nav--wrapper {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .nav--wrapper {
    float: right;
    margin-right: 38px;
    height: auto;
    margin-top: 2px;
  }
}
.nav--wrapper-content {
  color: #ffffff;
  line-height: 0;
  padding: 22px 0 22px;
  text-align: left;
  /* Medium devices (desktops, 1024px and up) */
}
.nav--wrapper-content span > a {
  padding: 0;
}
@media only screen and (min-width: 1024px) {
  .nav--wrapper-content {
    padding: 0;
  }
}
.nav--wrapper-content .header-nav-link {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 0.8px;
  line-height: 1.33;
  font-size: 12px;
  font-size: 0.75rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  text-align: right;
  color: #404040;
  position: relative;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .nav--wrapper-content .header-nav-link {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .nav--wrapper-content .header-nav-link {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1366px) {
  .nav--wrapper-content .header-nav-link {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
.nav--wrapper-content .header-nav-link:hover, .nav--wrapper-content .header-nav-link.nav-selected, .nav--wrapper-content .header-nav-link:focus {
  color: #0073cd;
}
@media only screen and (min-width: 1024px) {
  .nav--wrapper-content .header-nav-link:before {
    content: none;
    margin-right: 20px;
  }
}
.nav--wrapper-content + .nav--wrapper-content {
  border-top: 1px solid #e0e0e0;
}
.nav--wrapper-content-show {
  display: none;
}
.nav--wrapper-content.mega-nav-variant {
  position: relative;
}
.nav--wrapper-content.mega-nav-variant.nav--wrapper-show a:before {
  transform: rotate(90deg);
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .nav--wrapper-content.mega-nav-variant.nav--wrapper-show a:before {
    display: none;
  }
}
.nav--wrapper-content.mega-nav-variant.nav--wrapper-show .meganav--text-wrapper-list li.meganav--text-wrapper-list-item {
  position: relative;
}
.nav--wrapper-content.mega-nav-variant.nav--wrapper-show .meganav--text-wrapper-list li.meganav--text-wrapper-list-item a:before {
  display: none;
}
.nav--wrapper-content.mega-nav-variant.nav--wrapper-show .meganav--text-wrapper-list li.meganav--text-wrapper-list-item a:after {
  display: none;
}
.nav--wrapper-content.has-submenu {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .nav--wrapper-content.has-submenu {
    margin: 0 10px;
  }
}
.nav--wrapper-content.has-submenu > span > a {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .nav--wrapper-content.has-submenu > span > a {
    padding-right: 22px;
    left: 10px;
  }
  .nav--wrapper-content.has-submenu > span > a:before {
    content: "\e907";
    font-family: "icon-font", sans-serif;
    font-size: 30px;
    top: -11px;
    transform: none;
    background: none;
    right: -5px;
  }
}
.nav--wrapper-content.has-submenu.open > span > a:before {
  transform: rotate(180deg);
  right: -25px;
  top: 20px;
}
.nav--wrapper-show {
  padding-bottom: 0;
}
.nav--wrapper-show .link,
.nav--wrapper-show .header-nav-link {
  position: relative;
}

/* Medium devices (desktops, 1024px and up) */
@media only screen and (min-width: 1024px) {
  .nav--wrapper-content {
    margin: 0 0 0 20px;
  }
  .nav--wrapper-content:first-child {
    margin-left: 0;
  }
  .nav--wrapper-content + .nav--wrapper-content {
    border-top: 0;
  }
  .nav--wrapper-content.nav--wrapper-show {
    border-bottom: 0;
  }
  .nav--wrapper-content.nav--wrapper-show > div {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #ffffff;
    margin-left: 40%;
    margin-top: 5px;
  }
  .nav--wrapper-content.nav--wrapper-show > a {
    border: none;
    color: #404040;
  }
  .nav--wrapper-content.nav--wrapper-show > a:hover {
    color: #0073cd;
  }
  .nav--wrapper-content a {
    position: relative;
  }
  .nav--wrapper-content-show {
    position: relative;
    left: -42%;
    display: none;
  }
  .nav--wrapper-content .header-nav-link {
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 700;
  }
}
.lang-ar .header-body .header-body-nav-links .nav--wrapper-content {
  padding-left: 0;
  padding-right: 0;
}

.lang-ar .header-body {
  left: 0;
  right: auto;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-body {
    padding-left: 25px;
  }
}
.lang-ar .header-body-nav-links {
  float: left;
}
.lang-ar .header-body-profile-name {
  text-align: right;
}
.lang-ar .header-body-profile ul {
  text-align: right;
}
.lang-ar .header-body .header-body-nav-links .nav--wrapper-content > span > a:last-child {
  padding-right: 0;
  margin-left: 5px;
}
.lang-ar .c-header-top-icon {
  left: 20px;
  right: auto;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-header-top-icon {
    left: 0;
    right: 0;
  }
}
.lang-ar .c-header.header-logged .header-body .autocomplete-wrapper .input-container .form-input, .lang-ar .c-header.header-pre-logged .header-body .autocomplete-wrapper .input-container .form-input {
  padding-right: 0;
  padding-left: 35px;
}
.lang-ar .c-header.header-logged .header-body .autocomplete-wrapper .btn-submit, .lang-ar .c-header.header-pre-logged .header-body .autocomplete-wrapper .btn-submit {
  right: auto;
  left: 80px;
}
.lang-ar .c-header.header-logged .header-body .autocomplete-wrapper .autosuggest-list li, .lang-ar .c-header.header-pre-logged .header-body .autocomplete-wrapper .autosuggest-list li {
  text-align: right;
}
.lang-ar .c-header .c-header-wrapper .nav-item-search {
  right: auto;
  left: 23%;
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-header .c-header-wrapper .nav-item-search {
    left: 17%;
  }
}
.lang-ar .c-header .c-header-wrapper .nav-item-search .overlay-wrapper {
  left: 20%;
}
.lang-ar .c-header .c-header-wrapper .nav-item-search .overlay--close-button {
  border: 0;
  border-right: 1px solid #404040;
  position: absolute;
  left: 0;
  right: auto;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-header .c-header-wrapper .nav-item-search .overlay--close-button {
    padding-right: 10px;
  }
}
.lang-ar .c-header .c-header-wrapper .nav-item-search .autocomplete-wrapper .btn-submit {
  left: 0;
  right: auto;
  margin-left: 38px;
}
.lang-ar .c-header .c-header-wrapper .nav-item-search .autocomplete-wrapper .autosuggest-list:before {
  left: initial;
  right: 4%;
}
.lang-ar .c-header .c-header-wrapper .nav-item-search .autocomplete-wrapper .autosuggest-list li {
  text-align: right;
}
.lang-ar .c-header .c-header-wrapper .nav-item-search .overlay-content-container .overlay-content-inner-container {
  padding-left: auto;
  padding-right: 22px;
  left: auto;
  right: -22px;
}
.lang-ar .c-header-top .profile {
  left: 60px;
  right: auto;
}
.lang-ar .c-header-top .whatsapp {
  left: 104px;
  right: auto;
}
.lang-ar .c-header .c-header-top-icon.search {
  right: 0;
  left: 0;
  top: 47%;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-header .c-header-top-icon.search {
    top: 50%;
  }
}
.lang-ar .c-header .c-header-top-icon.hamburger {
  left: 15px;
  right: initial;
}
.lang-ar .c-header.sticky-header {
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-header.sticky-header .c-header-wrapper {
    padding-right: 33px;
  }
  .lang-ar .c-header.sticky-header .header-body {
    margin-left: 11%;
    margin-right: 0;
  }
  .lang-ar .c-header.sticky-header .header-bottom {
    left: 2px;
  }
  .lang-ar .c-header.sticky-header .header-bottom .btn-primary {
    right: auto;
    left: 30px;
  }
  .lang-ar .c-header.sticky-header .c-header-wrapper .c-header-top-logo {
    float: right;
    margin-top: -12px;
  }
}
.lang-ar .meganav--text-wrapper {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .meganav--text-wrapper {
    padding-right: 0;
    padding-left: 0px;
  }
}
.lang-ar .meganav--text-wrapper li {
  text-align: left;
  font-weight: bold;
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.63;
  letter-spacing: 0.8px;
  font-size: 16px;
  font-size: 1rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .lang-ar .meganav--text-wrapper li {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .meganav--text-wrapper li {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.56;
    letter-spacing: 0.9px;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .meganav--text-wrapper li {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.56;
    letter-spacing: 0.9px;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .meganav--text-wrapper li {
    text-align: right;
    font-size: 14px;
  }
}
.lang-ar .meganav--text-wrapper li a {
  text-align: right;
}
.lang-ar .meganav--text-wrapper-bodycopy {
  text-align: right;
}
.lang-ar .meganav--image-wrapper-list-content p,
.lang-ar .meganav--image-wrapper-list div p {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.lang-ar .header-bottom {
  padding: 15px 20px 10px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .lang-ar .header-bottom {
    padding: 12px 22px 18px;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom {
    padding: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom {
    position: absolute;
    left: 32px;
    right: auto;
  }
}
.lang-ar .header-bottom .btn-primary {
  margin-left: 0;
}
.lang-ar .header-bottom .btn-primary a {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.lang-ar .header-bottom .cart {
  right: auto;
  left: 124px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom .cart {
    right: 64px;
  }
}
.lang-ar .header-bottom .search {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom .search {
    right: -16px;
  }
}
.lang-ar .header-bottom .search,
.lang-ar .header-bottom .cart {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom .search,
  .lang-ar .header-bottom .cart {
    margin-right: 0;
    margin-left: 24px;
  }
}
.lang-ar .header-bottom .cart {
  left: auto;
  right: auto;
  margin: 0 10px;
  top: -4px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom .cart {
    top: 23px;
  }
}
.lang-ar .header-bottom-profile .profile.logged-in {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom-profile .profile.logged-in {
    padding-right: 0;
    padding-left: 12px;
  }
  .lang-ar .header-bottom-profile .profile.logged-in span:after {
    left: -12px;
  }
}
.lang-ar .header-bottom-lang-switcher {
  margin-right: 5px;
  margin-left: 0;
}
.lang-ar .header-bottom .btn-primary a {
  padding: 9px 20px;
}
.lang-ar .header-bottom .btn-primary a:before {
  top: 7px;
  left: 7px;
}
.lang-ar .header-bottom .selected-option {
  padding-left: 22px;
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.43;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  /* < 768px */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .lang-ar .header-bottom .selected-option {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom .selected-option {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .header-bottom .selected-option {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .header-bottom .selected-option:after {
    top: 8px;
    left: 13px;
    right: auto;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom .selected-option:after {
    top: 6px;
    left: 2px;
  }
}
.lang-ar .header-bottom .selected-option:before {
  left: auto;
  right: 0;
}
.lang-ar .header-bottom .selected-option.collapse:after {
  top: 3px;
  left: 2px;
  right: auto;
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-bottom .selected-option {
    padding-left: 17px;
    border-left: 0;
    padding-right: 0;
    margin-right: 10px;
  }
  .lang-ar .header-bottom .selected-option:after {
    right: auto;
  }
}
.lang-ar .header-bottom-btn-logout {
  text-align: right;
}
.lang-ar .nav--wrapper {
  margin-right: 0;
}
.lang-ar .nav--wrapper-show .link:after,
.lang-ar .nav--wrapper-show .header-nav-link:after {
  left: -24px;
  right: initial;
}
.lang-ar .nav--wrapper-content {
  text-align: right;
  padding-left: 0;
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .nav--wrapper-content .header-nav-link {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.43;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .lang-ar .nav--wrapper-content .header-nav-link {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .nav--wrapper-content .header-nav-link {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .nav--wrapper-content .header-nav-link {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .nav--wrapper-content .header-nav-link {
    right: 15px;
    padding-left: 20px;
    padding-right: 0;
    font-size: 12px;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .nav--wrapper-content.nav--wrapper-show > div {
    margin-left: 0;
    margin-right: 40%;
  }
  .lang-ar .nav--wrapper-content:first-child {
    margin-left: 15px;
    margin-right: 0;
  }
  .lang-ar .nav--wrapper-content:last-child {
    margin-left: 0;
    margin-right: 15px;
  }
  .lang-ar .nav--wrapper-content + .nav--wrapper-content {
    border-top: 0;
    margin-right: 0;
  }
}
.lang-ar .nav--wrapper-content.mega-nav-variant a:before, .lang-ar .nav--wrapper-content.mega-nav-variant a:after {
  height: 70%;
  top: 2px;
  left: auto;
  right: -20px;
}
.lang-ar .nav--wrapper-content.nav--wrapper-show .meganav--text-wrapper-list li.meganav--text-wrapper-list-item a {
  text-align: right;
}
.lang-ar .language-switcher {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .language-switcher {
    margin-left: 15px;
  }
}
.lang-ar .language-switcher .dropdown-menu {
  right: auto;
  left: 0;
  box-shadow: none;
}
.lang-ar .language-switcher .dropdown-menu:before {
  left: 8%;
  right: auto;
}
.lang-ar .language-switcher .dropdown-component .selected-option {
  /* < 768px */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .language-switcher .dropdown-component .selected-option.collapse:after {
    top: 6px;
    left: 13px;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .language-switcher .dropdown-component .selected-option:after {
    top: 8px;
    left: 8px;
  }
  .lang-ar .language-switcher .dropdown-component .selected-option.collapse:after {
    top: 6px;
    left: 8px;
  }
}
.lang-ar .language-switcher .dropdown-item span {
  padding-right: 0;
  text-align: right;
}

.lang-ar .nav--wrapper-content {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .nav--wrapper-content:nth-last-child(-n+3) .meganav {
    left: 0;
    right: auto;
  }
}
.lang-ar .nav--wrapper-content.has-submenu > span > a:before {
  top: -10px;
}
.lang-ar .header-body .header-body-nav-links .nav--wrapper-content.has-submenu {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-body .header-body-nav-links .nav--wrapper-content.has-submenu {
    margin-left: 15px;
    margin-right: 0;
  }
}
.lang-ar .header-body .header-body-nav-links .nav--wrapper-content.has-submenu > span > a:before {
  left: -10px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .header-body .header-body-nav-links .nav--wrapper-content.has-submenu > span > a:before {
    left: 10px;
    right: auto;
  }
}
.lang-ar .header-body .header-body-nav-links .nav--wrapper-content.has-submenu.open > span > a:before {
  top: 15px;
  right: auto;
  left: -8px;
}

/*
 * Config
 * @ Variables
 *
*/
/*calendar dropdown*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  YWW base Font size
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * H8
*/
/*
 * H9
*/
/*
 * H10
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
* form placeholder variables
*/
/*
 * Base
 * @ Colors
 *
*/
/* Colors */
/* Trip Advisor Color */
/* Guest checkout Color */
/*
 * Line height font
 */
/*
 * Border Radius
 */
/*
 * Border Radius Separate
 */
/*
 * Box Shadow
 */
/*
 * Widescreen
 */
/*
 * Desktop (PC's)
 */
/*
 * Wide screen Desktop (PC's)
 */
/*
 * Extra Wide screen Desktop (PC's)
 */
/*
 * Tablet
 */
/*
  * TabletOnly
  */
/*
 * upto Tablet
 */
/*
 * Tablet2
 */
/*
 * Mobile
 */
/*
 * No Screen
 */
/*
 * Transition
    usage example
      a{
      color: gray;
      @include transition(color .3s ease);
      &:hover {
        color: black;
      }
}
 */
/*
 * Opacity
 */
/*
 * Clearfix
 */
/*
 * Configure vendor
 */
/*
 * SVG stroke and fill
 */
/*
 * Transform
 */
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
 * Flex
 */
/*
 * New Design System - 2025
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
/*
 * Base
 * @ Gradients
 *
*/
/*
 * Line height font
 */
.tenant-yww {
  /* < 1023px */
}
.tenant-yww .cart-dropdown-wrap {
  background: #ffffff;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .cart-dropdown-wrap {
    border: 1px solid #e0e0e0 !important;
  }
  .tenant-yww .cart-dropdown-wrap::before {
    border: 1px solid #e0e0e0 !important;
  }
}
.tenant-yww .cart-dropdown-wrap.showCart {
  top: 48px;
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .tenant-yww .cart-dropdown-wrap.showCart {
    top: 61px;
  }
}
.lang-ar .tenant-yww .cart-dropdown-wrap.showCart:before {
  left: 15px;
  right: auto;
}
.tenant-yww .cart-dropdown-wrap.showCart .header-miniCart .b2c-mini-cart-wrapper {
  position: relative;
  max-width: none;
  bottom: 0;
  overflow: visible;
}
.tenant-yww .cart-dropdown-wrap.showCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper {
  border: 0;
}
.tenant-yww .cart-dropdown-wrap.showCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container .btn-primary {
  margin: 20px auto;
  width: calc(100% - 60px);
}
.tenant-yww .cart-dropdown-wrap.showCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container .btn-primary a {
  width: 100%;
  margin-bottom: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tenant-yww .cart-dropdown-wrap.showCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container .btn-primary a::before {
  background: transparent;
  border: 2px solid #000;
}
.tenant-yww .cart-dropdown-wrap.showCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container .btn-primary .btn-icon {
  display: none;
}
.tenant-yww .cart-dropdown-wrap.showCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .minicart-scroll-wrapper .ticket-section {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .cart-dropdown-wrap.showCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .minicart-scroll-wrapper .ticket-section {
    display: block;
  }
}
.tenant-yww .cart-dropdown-wrap.showCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .minicart-scroll-wrapper .ticket-section .product-details-wrapper .product-details {
  border-bottom: 0;
}
.tenant-yww .cart-dropdown-wrap.showCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .cart-summary-container {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .cart-dropdown-wrap.showCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .cart-summary-container {
    background: #E0EEFF;
  }
  .tenant-yww .cart-dropdown-wrap.showCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .cart-summary-container .total-price-container {
    background-color: transparent;
    color: #404040;
  }
  .tenant-yww .cart-dropdown-wrap.showCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .cart-summary-container .total-price-container .cart-total-price {
    font-weight: 700;
  }
  .tenant-yww .cart-dropdown-wrap.showCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .cart-summary-container .checkout-button.disabled .btn-primary .btn-icon {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='11' height='14' viewBox='0 0 11 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.89363 5.06181V4.10685C8.86763 2.89895 8.21526 1.79389 7.17593 1.19731C6.13663 0.600898 4.86357 0.600898 3.82424 1.19731C2.78494 1.79383 2.13242 2.89889 2.10643 4.10685V5.06181C1.68038 5.06181 1.27183 5.23286 0.970511 5.53746C0.669306 5.84196 0.5 6.25508 0.5 6.68579V12.1261V12.126C0.5 12.5568 0.669315 12.9699 0.970511 13.2743C1.27183 13.579 1.68038 13.75 2.10643 13.75H8.89357C9.31962 13.75 9.72828 13.5789 10.0295 13.2743C10.3308 12.9699 10.5 12.5568 10.5 12.126V6.68567C10.5 6.25497 10.3308 5.84184 10.0295 5.53734C9.72828 5.23273 9.31962 5.06169 8.89357 5.06169L8.89363 5.06181ZM6.14177 10.3186C6.14177 10.5507 6.01929 10.7651 5.82051 10.8812C5.62172 10.9972 5.37676 10.9972 5.178 10.8812C4.97921 10.7651 4.85674 10.5507 4.85674 10.3186V8.4916C4.85674 8.2596 4.97922 8.04515 5.178 7.92906C5.37678 7.81306 5.62174 7.81306 5.82051 7.92906C6.0193 8.04518 6.14177 8.2596 6.14177 8.4916V10.3186ZM7.60846 5.06181L3.39159 5.06192V4.10696C3.41199 3.35943 3.8183 2.67736 4.46228 2.30944C5.10625 1.94161 5.89377 1.94161 6.53778 2.30944C7.18176 2.67739 7.58807 3.35943 7.60846 4.10696V5.06181Z' fill='white'/%3E%3C/svg%3E%0A");
  }
  .tenant-yww .cart-dropdown-wrap.showCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .cart-summary-container .checkout-button .btn-primary {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tenant-yww .cart-dropdown-wrap.showCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .cart-summary-container .final-price-container .final-price-container-wrapper .cart-total-label, .tenant-yww .cart-dropdown-wrap.showCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .cart-summary-container .final-price-container .final-price-container-wrapper .cart-total-price {
    color: #000 !important;
  }
}
@media (min-width: 320px) and (max-width: 1024px) {
  .tenant-yww .booking--page .page-container .c-purchase-journey .b2c-mini-cart-wrapper.page-scrolled {
    top: 61px;
  }
}
.tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component {
  background: transparent;
}
.tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper {
  /* Medium devices (desktops, 1024px and up) */
  /* < 1023px */
}
.tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container {
  background-color: #ffffff;
  /* < 1023px */
  /* < 1023px */
}
.tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container .empty-title {
  color: #404040;
  font-weight: 800;
  letter-spacing: 1.2px;
  line-height: 1.22;
  font-size: 1.125rem;
  margin-top: 0;
}
.tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container .empty-cart-container-image {
  display: none;
}
@media (min-width: 320px) and (max-width: 1024px) {
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container .empty-title {
    padding: 12px 24px;
  }
}
@media (min-width: 320px) and (max-width: 1024px) {
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container .btn-primary {
    background: transparent;
    width: calc(100% - 60px);
    margin: 0 auto;
    padding: 0 0 20px;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container .btn-primary a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container .btn-primary a::before {
    background: transparent;
    border: 2px solid #000;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container .btn-primary a .btn-icon {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='11' height='14' viewBox='0 0 11 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.89363 5.06181V4.10685C8.86763 2.89895 8.21526 1.79389 7.17593 1.19731C6.13663 0.600898 4.86357 0.600898 3.82424 1.19731C2.78494 1.79383 2.13242 2.89889 2.10643 4.10685V5.06181C1.68038 5.06181 1.27183 5.23286 0.970511 5.53746C0.669306 5.84196 0.5 6.25508 0.5 6.68579V12.1261V12.126C0.5 12.5568 0.669315 12.9699 0.970511 13.2743C1.27183 13.579 1.68038 13.75 2.10643 13.75H8.89357C9.31962 13.75 9.72828 13.5789 10.0295 13.2743C10.3308 12.9699 10.5 12.5568 10.5 12.126V6.68567C10.5 6.25497 10.3308 5.84184 10.0295 5.53734C9.72828 5.23273 9.31962 5.06169 8.89357 5.06169L8.89363 5.06181ZM6.14177 10.3186C6.14177 10.5507 6.01929 10.7651 5.82051 10.8812C5.62172 10.9972 5.37676 10.9972 5.178 10.8812C4.97921 10.7651 4.85674 10.5507 4.85674 10.3186V8.4916C4.85674 8.2596 4.97922 8.04515 5.178 7.92906C5.37678 7.81306 5.62174 7.81306 5.82051 7.92906C6.0193 8.04518 6.14177 8.2596 6.14177 8.4916V10.3186ZM7.60846 5.06181L3.39159 5.06192V4.10696C3.41199 3.35943 3.8183 2.67736 4.46228 2.30944C5.10625 1.94161 5.89377 1.94161 6.53778 2.30944C7.18176 2.67739 7.58807 3.35943 7.60846 4.10696V5.06181Z' fill='white'/%3E%3C/svg%3E%0A");
  }
}
.tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .cart-summary-container .checkout-button .btn-primary a {
  /* < 1023px */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .cart-summary-container .checkout-button .btn-primary a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .cart-summary-container .checkout-button .btn-primary a:hover .btn-icon, .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .cart-summary-container .checkout-button .btn-primary a:focus .btn-icon {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='11' height='14' viewBox='0 0 11 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.89363 5.06181V4.10685C8.86763 2.89895 8.21526 1.79389 7.17593 1.19731C6.13663 0.600898 4.86357 0.600898 3.82424 1.19731C2.78494 1.79383 2.13242 2.89889 2.10643 4.10685V5.06181C1.68038 5.06181 1.27183 5.23286 0.970511 5.53746C0.669306 5.84196 0.5 6.25508 0.5 6.68579V12.1261V12.126C0.5 12.5568 0.669315 12.9699 0.970511 13.2743C1.27183 13.579 1.68038 13.75 2.10643 13.75H8.89357C9.31962 13.75 9.72828 13.5789 10.0295 13.2743C10.3308 12.9699 10.5 12.5568 10.5 12.126V6.68567C10.5 6.25497 10.3308 5.84184 10.0295 5.53734C9.72828 5.23273 9.31962 5.06169 8.89357 5.06169L8.89363 5.06181ZM6.14177 10.3186C6.14177 10.5507 6.01929 10.7651 5.82051 10.8812C5.62172 10.9972 5.37676 10.9972 5.178 10.8812C4.97921 10.7651 4.85674 10.5507 4.85674 10.3186V8.4916C4.85674 8.2596 4.97922 8.04515 5.178 7.92906C5.37678 7.81306 5.62174 7.81306 5.82051 7.92906C6.0193 8.04518 6.14177 8.2596 6.14177 8.4916V10.3186ZM7.60846 5.06181L3.39159 5.06192V4.10696C3.41199 3.35943 3.8183 2.67736 4.46228 2.30944C5.10625 1.94161 5.89377 1.94161 6.53778 2.30944C7.18176 2.67739 7.58807 3.35943 7.60846 4.10696V5.06181Z' fill='white'/%3E%3C/svg%3E%0A");
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .cart-summary-container .checkout-button .btn-primary {
    width: calc(100% - 48px);
    margin: auto;
    outline: none !important;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .cart-summary-container .checkout-button .btn-primary a:before {
    background: transparent;
    border: 2px solid #000;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .minicart-scroll-wrapper .ticket-section {
    margin: 0;
    padding: 20px 25px 0;
    color: #404040;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .minicart-scroll-wrapper .ticket-section .product-details-wrapper .product-title-wrapper .heading-4 {
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    color: #404040;
    letter-spacing: normal;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-validity {
    display: block !important;
    line-height: 20px !important;
    flex-direction: row;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-validity .valid-date {
    display: flex;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-validity .date-label {
    color: #404040;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: normal;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-validity .date-value {
    letter-spacing: normal;
    color: #404040;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-subsection-container .ticket-type .heading-4 {
    color: #404040;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: normal;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-subsection-container .ticket-type .price {
    color: #404040;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-subsection-container input.quantity {
    border: 1px solid #38bfff;
    border-radius: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.52px;
    color: #000;
    /* < 1023px */
  }
}
@media only screen and (min-width: 1024px) and (min-width: 320px) and (max-width: 1024px) {
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-subsection-container input.quantity {
    color: #404040;
  }
}
@media (min-width: 320px) and (max-width: 1024px) {
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content {
    background-color: transparent;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .ticket-section {
    display: block;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .ticket-section .product-details-wrapper .product-title-wrapper .heading-4 {
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    color: #404040;
    letter-spacing: normal;
    /* < 1023px */
  }
}
@media (min-width: 320px) and (max-width: 1024px) and (min-width: 320px) and (max-width: 1024px) {
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .ticket-section .product-details-wrapper .product-title-wrapper .heading-4 {
    color: #333333;
  }
}
@media (min-width: 320px) and (max-width: 1024px) {
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .ticket-section .product-details-wrapper .ticket-validity {
    flex-direction: row;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .ticket-section .product-details-wrapper .ticket-validity .date-label {
    color: #404040;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: normal;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .ticket-section .product-details-wrapper .ticket-validity .date-value {
    letter-spacing: normal;
    color: #404040;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .ticket-section .product-details-wrapper .ticket-subsection-container .ticket-type .heading-4 {
    color: #404040;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: normal;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .ticket-section .product-details-wrapper .ticket-subsection-container .ticket-type .price {
    color: #404040;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .ticket-section .product-details-wrapper .ticket-subsection-container input.quantity {
    font-weight: 400 !important;
    border: 1px solid #e0e0e0;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .ticket-section .product-details-wrapper .ticket-subsection-container input.quantity-active {
    border: 1px solid #38bfff;
    font-weight: 700 !important;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container {
    padding: 15px 24px 20px;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .c-mini-check-icon {
    margin-right: 0;
  }
  .lang-ar .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .c-mini-check-icon {
    margin-left: 0;
    margin-right: auto;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary-container {
    padding: 0;
    margin-top: 0;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary-container .checkout-button {
    padding: 0;
    margin: 0;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary-container .checkout-button.disabled {
    opacity: 0.3;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary-container .checkout-button .btn-primary {
    width: 100%;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary-container .checkout-button .btn-primary a {
    background: #ff9d1b;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section {
    display: block;
    color: #404040;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .product-title-wrapper .heading-4 {
    line-height: 24px;
    font-size: 20px;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .ticket-validity {
    line-height: 20px;
    display: flex;
    flex-direction: row !important;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .ticket-sub-section .ticket-item .ticket-type .price {
    letter-spacing: normal;
    font-size: 16px;
  }
  .tenant-yww .b2c-mini-cart-wrapper .c-b2c-mini-cart.component .c-b2c-cart .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .ticket-sub-section input.quantity {
    font-weight: 400;
    border-radius: 0;
  }
}

.buy-annual-pass--page.empty-cart .tenant-yww .v-parks-sticky-header.custom-mobile-header .c-header.component {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .buy-annual-pass--page.empty-cart .tenant-yww .v-parks-sticky-header.custom-mobile-header .c-header.component {
    padding-bottom: 0;
  }
}
.buy-annual-pass--page.cart-has-items .tenant-yww .v-parks-sticky-header.custom-mobile-header.sticky-header .c-header.component {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .buy-annual-pass--page.cart-has-items .tenant-yww .v-parks-sticky-header.custom-mobile-header.sticky-header .c-header.component {
    padding-bottom: 0 !important;
    margin-bottom: 112px !important;
  }
}
.buy-annual-pass--page.cart-has-items .tenant-yww .v-parks-sticky-header.custom-mobile-header .c-header.component {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .buy-annual-pass--page.cart-has-items .tenant-yww .v-parks-sticky-header.custom-mobile-header .c-header.component {
    padding-bottom: 90px;
    margin-bottom: 0 !important;
  }
}
.buy-annual-pass--page .tenant-yww .v-parks-sticky-header.custom-mobile-header.sticky-header .cart-dropdown-wrap {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .buy-annual-pass--page .tenant-yww .v-parks-sticky-header.custom-mobile-header.sticky-header .cart-dropdown-wrap {
    top: 60px;
  }
}
.buy-annual-pass--page .tenant-yww .v-parks-sticky-header.custom-mobile-header .c-header.component .c-purchase-journey .b2c-mini-cart-wrapper {
  bottom: unset;
  position: unset;
}
.buy-annual-pass--page .tenant-yww .v-parks-sticky-header.custom-mobile-header .c-header.component .c-mini-check-icon {
  transform: rotate(180deg) !important;
  transition: all 0.2s ease-out;
  top: 0 !important;
}
.buy-annual-pass--page .tenant-yww .v-parks-sticky-header.custom-mobile-header .c-header.component.show-cart-content .cart-dropdown-wrap {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .buy-annual-pass--page .tenant-yww .v-parks-sticky-header.custom-mobile-header .c-header.component.show-cart-content .cart-dropdown-wrap {
    min-height: 100vh !important;
  }
}
.buy-annual-pass--page .tenant-yww .v-parks-sticky-header.custom-mobile-header .c-header.component.show-cart-content .cart-dropdown-wrap .c-b2c-cart-content .minicart-scroll-wrapper {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .buy-annual-pass--page .tenant-yww .v-parks-sticky-header.custom-mobile-header .c-header.component.show-cart-content .cart-dropdown-wrap .c-b2c-cart-content .minicart-scroll-wrapper {
    display: block;
  }
}
.buy-annual-pass--page .tenant-yww .v-parks-sticky-header.custom-mobile-header .c-header.component.show-cart-content .cart-dropdown-wrap .c-mini-check-icon {
  transform: rotate(359deg) !important;
}
.buy-annual-pass--page .tenant-yww .cart-dropdown-wrap {
  /* < 1023px */
}
.buy-annual-pass--page .tenant-yww .cart-dropdown-wrap.showCart {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .buy-annual-pass--page .tenant-yww .cart-dropdown-wrap.showCart {
    min-height: 100vh !important;
  }
}
.buy-annual-pass--page .tenant-yww .cart-dropdown-wrap.showCart .c-b2c-mini-cart {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .buy-annual-pass--page .tenant-yww .cart-dropdown-wrap.showCart .c-b2c-mini-cart {
    height: calc(100vh - 61px);
  }
}
.buy-annual-pass--page .tenant-yww .cart-dropdown-wrap.showCart .minicart-scroll-wrapper {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .buy-annual-pass--page .tenant-yww .cart-dropdown-wrap.showCart .minicart-scroll-wrapper {
    display: block;
  }
}
@media (min-width: 320px) and (max-width: 1024px) {
  .buy-annual-pass--page .tenant-yww .cart-dropdown-wrap {
    top: 60px;
    min-height: auto !important;
  }
}
.buy-annual-pass--page .tenant-yww .cart-dropdown-wrap .c-b2c-mini-cart .c-b2c-cart-wrapper {
  border: none;
}
.buy-annual-pass--page .tenant-yww .cart-dropdown-wrap .minicart-scroll-wrapper {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .buy-annual-pass--page .tenant-yww .cart-dropdown-wrap .minicart-scroll-wrapper {
    display: none;
  }
}

.c-header-revamp-component .revamp-header-container .c-header-top-icon span {
  display: flex;
  gap: 8px;
}
.c-header-revamp-component .revamp-header-container .tooltipOpen {
  color: #3e8bcf;
}
.c-header-revamp-component .revamp-header-container .tooltipOpen a,
.c-header-revamp-component .revamp-header-container .tooltipOpen p,
.c-header-revamp-component .revamp-header-container .tooltipOpen span {
  color: #3e8bcf;
}
.c-header-revamp-component .revamp-header-container .header-revamp-tooltip {
  position: relative;
}
.c-header-revamp-component .revamp-header-container .header-revamp-tooltip .revamp-header-nav-link-dropdown svg {
  width: 21px;
  height: 20px;
}
.c-header-revamp-component .revamp-header-container .header-revamp-tooltip .revamp-header-nav-link-dropdown.tooltipOpen svg {
  stroke: #3e8bcf !important;
  transform: rotate(180deg);
}
.c-header-revamp-component .revamp-header-container .header-revamp-tooltip .revamp-header-nav-link-dropdown.tooltipOpen svg path {
  stroke: #3e8bcf !important;
}
.c-header-revamp-component .revamp-header-container .header-revamp-dropdown {
  border-top: 4px solid #3e8bcf;
  background: #ffffff;
  position: absolute;
  z-index: 10;
  top: 40px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  /* < 768px */
}
.c-header-revamp-component .revamp-header-container .header-revamp-dropdown .header-revamp-list-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.c-header-revamp-component .revamp-header-container .header-revamp-dropdown .helper-upcoming {
  display: flex;
  padding: 0px 4px 1px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background-color: #f20000;
  color: white;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-container .header-revamp-dropdown.profile-dropdown-mini-header {
    width: 176px;
    padding: 8px 0px;
    top: 46px !important;
  }
  .c-header-revamp-component .revamp-header-container .header-revamp-dropdown.profile-dropdown-mini-header .header-revamp-list {
    width: 100%;
  }
  .c-header-revamp-component .revamp-header-container .header-revamp-dropdown.language-mini-header-mobile {
    width: 181px;
    padding: 8px 0px;
    top: 41px !important;
  }
  .c-header-revamp-component .revamp-header-container .header-revamp-dropdown.language-mini-header-mobile .header-revamp-list {
    width: 100%;
  }
}
.c-header-revamp-component .revamp-header-container .header-revamp-dropdown.language-mini-header-mobile {
  top: 39px !important;
}
.c-header-revamp-component .revamp-header-container .header-revamp-dropdown.profile-dropdown-mini-header {
  top: 42px !important;
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .c-header-revamp-component .revamp-header-container .header-revamp-dropdown.profile-dropdown-mini-header {
    top: 35px !important;
  }
}
.c-header-revamp-component .revamp-header-container .header-revamp-list {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.lang-ar .c-header-revamp-component .revamp-header-container .header-revamp-list {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-header-revamp-component .revamp-header-container .header-revamp-list .logout-revamp-list {
  width: 21px;
  height: 20px;
}
.c-header-revamp-component .revamp-header-container .header-revamp-list.en {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
}
.lang-ar .c-header-revamp-component .revamp-header-container .header-revamp-list.logout-list-item {
  justify-content: revert;
}
.lang-ar .c-header-revamp-component .revamp-header-container .header-revamp-list.logout-list-item .logout-revamp-list {
  transform: rotate(180deg);
  gap: 8px;
}
.lang-ar .c-header-revamp-component .revamp-header-container .header-revamp-list.logout-list-item .logout-revamp-list .logout-icon {
  width: 21px;
  height: 20px;
}
.c-header-revamp-component .revamp-header-container .header-revamp-list.logout-list-item a:hover {
  color: #333333;
}
.c-header-revamp-component .revamp-header-container .header-revamp-list.selected {
  font-weight: 700;
  color: #0073cd;
}
.c-header-revamp-component .revamp-header-container .c-header-top-icon {
  width: unset;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top {
  display: flex;
  padding: 8px 35px 8px 267px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top {
    background: #000;
  }
  .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header {
    background: #353535;
  }
}
.lang-ar .c-header-revamp-component .revamp-header-container .revamp-header-top {
  padding: 8px 267px 8px 35px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name {
  color: #333333;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-in-user-header {
  gap: 4px;
  color: #3e8bcf;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-in-user-header {
    gap: 0px;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-in-user-header span {
  color: #0073CD;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-in-user-header .user-name {
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  color: #0073CD;
  font-weight: 700;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-in-user-header svg {
  width: 24px;
  height: 18px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-in-user-header #header-dropdown-icon {
  stroke: #3e8bcf !important;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-in-user-header #header-dropdown-icon path {
  stroke: #3e8bcf !important;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .language-header-name .logged-out-user-header svg {
  width: 24px;
  height: 24px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu {
  width: 50%;
  display: flex;
  gap: 24px;
  justify-content: end;
  align-items: center;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu {
    gap: 4px;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .header-miniCart {
  top: calc(100% + 7px);
  right: 3px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  width: 368px;
}
.lang-ar .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .header-miniCart {
  left: 0px;
  right: auto;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .header-miniCart .c-b2c-cart-wrapper {
  border-top: 4px solid #3e8bcf;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content {
  display: flex;
  flex-direction: column;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper {
  border: none;
  border-bottom: none;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section {
  padding: 24px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .ticket-validity {
  margin-top: 10px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .product-details {
  padding-bottom: 20px;
}
.buy-annual-pass--page .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section {
  display: block !important;
  border: none;
  border-bottom: 1px solid #d3d3d3;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-subsection-container {
  padding-top: 0px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-subsection-container .show .ticket-sub-section {
  margin-bottom: 0px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-subsection-container .show .ticket-sub-section .ticket-info .c-spinner .quantity {
  border-radius: 4px;
}
.buy-annual-pass--page .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container {
  border-top: none;
  padding-bottom: 0px;
  background-color: #f7f7f7;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
  border: none;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .cart-summary-details {
  width: 100%;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .cart-summary-details .cart-price-container .total-price-container {
  padding: 0px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container {
  width: 100%;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container {
  border-top: 0px;
  border-top: none;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .checkout-button {
  padding-bottom: 0px !important;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .checkout-button .btn-primary {
  width: calc(100% - 12px);
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .checkout-button .btn-primary a {
  -webkit-border-top-left-radius: 18px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 18px;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 18px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 18px;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 18px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  display: inline-block;
  margin-bottom: 0;
  padding: 10px 30px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #0073cd;
  color: #ffffff;
  border: 2px solid #0073cd;
  /* Medium devices (desktops, 1024px and up) */
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px !important;
}
@media (min-width: 768px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .checkout-button .btn-primary a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .checkout-button .btn-primary a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .checkout-button .btn-primary a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .checkout-button .btn-primary a:focus {
  -webkit-border-top-left-radius: 18px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 18px;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 18px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 18px;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 18px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 0;
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .checkout-button .btn-primary a:hover, .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .checkout-button .btn-primary a:focus {
    background-color: #ffffff;
    color: #0073cd;
    border: 2px solid #0073cd;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .checkout-button .btn-primary a.disabled, .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .checkout-button .btn-primary a[disabled], fieldset[disabled] .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container .checkout-button .btn-primary a {
  background-color: #a4a4a4;
  color: #ffffff;
  border: 2px solid #a4a4a4;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .continue-shopping-cta {
  width: 100%;
  padding: 0px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .continue-shopping-cta .continue-shopping-cta-text {
  color: #0073CD;
  font-size: 14px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 1px;
  border-bottom: 1px solid #0073CD;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container span svg {
  width: 24px;
  height: 24px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .mini-cart-wrapper-container .total-price-indication {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3e8bcf;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .header-revamp-dropdown {
  top: 26px;
}
.lang-ar .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .header-revamp-dropdown {
  left: 0px;
}
.lang-en .c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .header-revamp-dropdown {
  right: 5px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .header-revamp-dropdown.profile-dropdown {
  top: 33px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .revamp-header-nav-link {
  color: #ffffff;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 1px;
  cursor: pointer;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .revamp-header-nav-link.tooltipOpen .language-header-name {
  color: #3e8bcf;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .revamp-header-nav-link.tooltipOpen svg#header-dropdown-icon {
  transform: rotate(180deg);
  stroke: #3e8bcf;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .header-revamp-tooltip .revamp-header-nav-link.tooltipOpen svg#header-dropdown-icon path {
  stroke: #3e8bcf;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .search-component .container {
  width: 100%;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top .revamp-header-menu .search-component .container .popular-search.suggestion .search-list .list-item .list-item-text {
  color: #000;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header {
  /* < 768px */
  /* Medium devices (desktops, 1024px and up) */
  background: #1C5EA2;
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header {
    padding: 0px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header {
    padding: 20px 80px;
  }
}
.upgrade--page .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .upgrade--page .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header {
    padding: 8px 75px;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .redirect-btn {
  color: #ffffff;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0px;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .redirect-btn {
    padding: 0px;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .redirect-btn svg {
  width: 21px;
  height: 20px;
}
.lang-ar .c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .redirect-btn svg {
  transform: rotate(180deg);
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .redirect-btn #redirect-btn {
  stroke: #fff;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .dark-logo picture {
  width: 85px;
  height: 40px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .dark-logo picture img {
  padding: 0;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .language-header-name {
  color: #fff !important;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .language-header-name #header-dropdown-icon path {
  stroke: #fff !important;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .language-header-name .header-profile-icon-mini-header-white {
  height: 18px;
  width: 18px;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .language-header-name .header-profile-icon-white-mini-header {
  stroke: #fff;
}
.c-header-revamp-component .revamp-header-container .revamp-header-top.mini-header .language-header-name .user-name {
  color: #fff;
}
.c-header-revamp-component .revamp-header-container .revamp-header-bottom-body .header-revamp-list {
  position: relative;
  animation: slideInBottom 0.4s ease-out;
}
@keyframes slideInBottom {
  0% {
    top: 30px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-bottom-body .header-revamp-list .arrow-icon {
  opacity: 0;
  transform: translateX(-20px);
  display: block;
}
.c-header-revamp-component .revamp-header-container .revamp-header-bottom-body .header-revamp-list:hover .arrow-icon {
  opacity: 1;
  transform: translateX(0);
  animation: slideInBounce 0.3s ease-out;
}
@keyframes slideInBounce {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  33% {
    transform: translateX(2px);
    opacity: 0.5;
  }
  63% {
    transform: translateX(5px);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-bottom-body .header-revamp-list:hover {
  background-color: #EEF6FF;
  color: #6E6E6E;
  border-radius: 4px;
}
.c-header-revamp-component.mini-header {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component.mini-header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 91;
  }
}

/* < 768px */
@media (min-width: 320px) and (max-width: 767px) {
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section {
    padding: 24px;
    padding-bottom: 0px;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .global-cart-error {
    margin-bottom: 20px;
  }
  .buy-annual-pass--page .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section {
    border: none;
    border-bottom: 1px solid #d3d3d3;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .ticket-validity {
    margin-top: 10px;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper .ticket-section .product-details-wrapper .ticket-subsection-container .show .early-bird-wrap {
    margin-bottom: 0px;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container {
    border-top: 1px solid #d3d3d3;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .headerRevamp .header-miniCart .header-miniCart .c-purchase-journey .purchase-content .b2c-mini-cart-wrapper .c-b2c-mini-cart .headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .c-b2c-cart-content .cart-summary-container {
    border-top: 0px;
  }
  .buy-annual-pass--page .tenant-fwad .cart-dropdown-wrap .c-b2c-mini-cart {
    height: calc(100vh - 61px) !important;
  }
}
.col-lg-4.col-md-12.col-sm-12.page-scrolled {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .col-lg-4.col-md-12.col-sm-12.page-scrolled {
    top: 55px !important;
  }
  .expanded-mycart .col-lg-4.col-md-12.col-sm-12.page-scrolled {
    top: 0px !important;
  }
}

/* < 768px */
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-yww .revamp-header-notice-component {
    display: flex;
    padding: 12px 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  .tenant-yww .revamp-header-notice-component.c-cookies {
    z-index: unset;
  }
  .tenant-yww .revamp-header-notice-component .revamp-header-note {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    /* Mobile/Body/Regular/xs */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 183.333% */
    letter-spacing: 0px;
  }
  .tenant-yww .revamp-header-notice-component span svg {
    width: 25px;
    height: 24px;
  }
  .tenant-yww .revamp-header-notice-component .close-icon {
    width: 24px;
    height: 24px;
  }
  .tenant-yww .revamp-header-notice-component.component {
    margin-bottom: 0px;
  }
}
.c-header-revamp-component .revamp-header-notice-component {
  display: flex;
  padding: 12px 35px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.c-header-revamp-component .revamp-header-notice-component span svg {
  width: 25px;
  height: 24px;
}
.c-header-revamp-component .revamp-header-notice-component p {
  color: #000;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
}
.c-header-revamp-component .revamp-header-notice-component p a {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}
.c-header-revamp-component .revamp-header-notice-component p a:hover {
  color: inherit;
}
.c-header-revamp-component .revamp-header-notice-component .revamp-header-note {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 8px;
}
.c-header-revamp-component .revamp-header-notice-component .revamp-header-note p {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-header-revamp-component .revamp-header-notice-component .close-icon {
  width: 25px;
  height: 24px;
}
.c-header-revamp-component .revamp-header-top {
  background: linear-gradient(270deg, #EEF6FF 80.84%, #FFF 91.02%);
}
.c-header-revamp-component .revamp-header-top .revamp-header-openinghours-container .revamp-header-openinghours {
  display: flex;
  cursor: pointer;
  gap: 4px;
}
.c-header-revamp-component .revamp-header-top .revamp-header-openinghours-container .revamp-header-openinghours .right-chevron-icon {
  height: 20px;
  width: 20px;
}
.lang-ar .c-header-revamp-component .revamp-header-top .revamp-header-openinghours-container .revamp-header-openinghours .right-chevron-icon {
  transform: rotate(180deg);
}
.c-header-revamp-component .revamp-header-top .revamp-header-openinghours-container .revamp-header-openinghours h4 {
  color: #404040;
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 1px;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 600;
  font-weight: 800;
  font-size: 14px;
}
.lang-ar .c-header-revamp-component .revamp-header-top .revamp-header-openinghours-container .revamp-header-openinghours h4 {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  letter-spacing: 0px;
}
.c-header-revamp-component .revamp-header-bottom {
  background-color: #ffffff;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-nav-wrapper .revamp-header-nav-link {
  font-family: "Amaranth", "Open Sans", Arial, sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0.6000000238px;
  cursor: pointer;
  color: #000;
  text-transform: initial;
}
.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-nav-wrapper .revamp-header-nav-link {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-nav-wrapper .revamp-header-nav-link:hover {
  color: #0073CD;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
  -webkit-border-top-left-radius: 18px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 18px;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 18px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 18px;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 18px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  display: inline-block;
  margin-bottom: 0;
  padding: 10px 30px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #ff9d1b;
  color: #404040;
  border: 2px solid #ff9d1b;
  /* Medium devices (desktops, 1024px and up) */
  padding: 10px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 0;
}
@media (min-width: 768px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:focus {
  -webkit-border-top-left-radius: 18px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 18px;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 18px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 18px;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 18px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 0;
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:hover, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:focus {
    background-color: #0073cd;
    color: #ffffff;
    opacity: 1;
    box-shadow: none;
    outline: 0;
    border: 2px solid #0073cd;
  }
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn.disabled, .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn[disabled], fieldset[disabled] .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
  background-color: #a4a4a4;
  color: #ffffff;
  border: 2px solid #a4a4a4;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn a {
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  line-height: 24px;
  font-weight: 700;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:hover {
  cursor: pointer;
  color: #ffffff;
  border: 0;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn:hover a {
  color: #ffffff;
}
.lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-bottom-body .revamp-header-body-nav-links .revamp-header-btn {
  margin-left: 7px;
  margin-right: 0px;
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-top-logo {
  position: absolute;
  z-index: 1001;
  height: 84px;
  bottom: 8px;
  left: 20px;
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-bottom .revamp-header-top-logo {
    z-index: 100;
  }
}
.c-header-revamp-component .revamp-header-bottom .revamp-header-top-logo a, .c-header-revamp-component .revamp-header-bottom .revamp-header-top-logo a img {
  height: 100%;
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-header-revamp-component .revamp-header-bottom .revamp-header-top-logo {
    left: unset;
    right: 20px;
  }
}
.c-header-revamp-component .revamp-header-container .revamp-header-menu .mini-cart-wrapper-container .total-price-indication {
  background-color: #0073cd !important;
}
.c-header-revamp-component .revamp-header-container .revamp-header-menu .mini-cart-wrapper-container .total-price-indication .icon-container .total-quantity {
  color: #ffffff;
}

.humburger-menu-open {
  position: relative;
}
.humburger-menu-open:not(.lang-ar) {
  animation: bodySlideLeftToRight 0.2s ease-in-out;
}
@keyframes bodySlideLeftToRight {
  from {
    left: 0;
  }
  to {
    left: 200px;
  }
}
.humburger-menu-open.lang-ar {
  animation: bodySlideRightToLeft 0.2s ease-in-out;
}
@keyframes bodySlideRightToLeft {
  from {
    right: 0;
  }
  to {
    right: 200px;
  }
}

.humburger-menu-close {
  position: relative;
}
.humburger-menu-close:not(.lang-ar) {
  animation: bodySlideCloseLeftToRight 0.2s ease-in-out;
}
@keyframes bodySlideCloseLeftToRight {
  from {
    left: 200px;
  }
  to {
    left: 0;
  }
}
.humburger-menu-close.lang-ar {
  animation: bodySlideCloseRightToLeft 0.2s ease-in-out;
}
@keyframes bodySlideCloseRightToLeft {
  from {
    right: 200px;
  }
  to {
    right: 0;
  }
}

/* < 768px */
@media (min-width: 320px) and (max-width: 767px) {
  .slider-close-button {
    position: absolute;
    top: 20px;
    z-index: 13;
    right: 20px;
  }
  .lang-ar .slider-close-button {
    left: 20px;
    right: auto;
  }
  .has-sub-menu .slider-close-button {
    position: fixed;
  }
  .c-header-revamp-component {
    display: flex;
    height: 60px;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    background: #ffffff;
    flex-shrink: 0;
    /* < 768px */
  }
}
@media (min-width: 320px) and (max-width: 767px) and (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component {
    height: 56px;
    padding: 16px 20px;
  }
  .c-header-revamp-component.mini-header {
    background-color: #1C5EA2;
    height: auto;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component svg {
    width: 24px;
    height: 20px;
  }
  .c-header-revamp-component picture {
    width: 85px;
    height: unset;
  }
  .c-header-revamp-component.mobile {
    z-index: 91;
  }
  .c-header-revamp-component.mobile .dark-logo {
    position: absolute;
    left: 50%;
    transform: translate(-50px, 0px);
  }
  .c-header-revamp-component.mobile .hamburger-slider .dark-logo {
    position: initial;
    transform: none;
  }
  .c-header-revamp-component.mobile .mini-cart-wrapper-container .total-price-indication {
    background-color: #0073cd;
    color: #ffffff;
  }
  .c-header-revamp-component.mobile .mini-cart-wrapper-container .total-price-indication .icon-container .total-quantity {
    color: #ffffff;
  }
  .c-header-revamp-component .revamp-header-btn {
    -webkit-border-top-left-radius: 18px;
    -webkit-border-top-right-radius: 0;
    -webkit-border-bottom-right-radius: 18px;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius-topleft: 18px;
    -moz-border-radius-topright: 0;
    -moz-border-radius-bottomright: 18px;
    -moz-border-radius-bottomleft: 0;
    border-top-left-radius: 18px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 0;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 1.29;
    font-size: 14px;
    font-size: 0.875rem;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    /* Medium devices (desktops, 1366px and up) */
    display: inline-block;
    margin-bottom: 0;
    padding: 10px 30px;
    position: relative;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    background-color: #0073cd;
    color: #ffffff;
    border: 2px solid #0073cd;
    /* Medium devices (desktops, 1024px and up) */
    border-radius: 0px;
    border: none;
    height: 34px;
    padding: 8px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    transform: none;
    /* Medium devices (desktops, 1024px and up) */
  }
}
@media (min-width: 320px) and (max-width: 767px) and (min-width: 768px) {
  .c-header-revamp-component .revamp-header-btn {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-btn {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1366px) {
  .c-header-revamp-component .revamp-header-btn {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-btn:focus {
    -webkit-border-top-left-radius: 18px;
    -webkit-border-top-right-radius: 0;
    -webkit-border-bottom-right-radius: 18px;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius-topleft: 18px;
    -moz-border-radius-topright: 0;
    -moz-border-radius-bottomright: 18px;
    -moz-border-radius-bottomleft: 0;
    border-top-left-radius: 18px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 0;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-btn:hover, .c-header-revamp-component .revamp-header-btn:focus {
    background-color: #ffffff;
    color: #0073cd;
    border: 2px solid #0073cd;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-header-revamp-component .revamp-header-btn.disabled, .c-header-revamp-component .revamp-header-btn[disabled], fieldset[disabled] .c-header-revamp-component .revamp-header-btn {
    background-color: #a4a4a4;
    color: #ffffff;
    border: 2px solid #a4a4a4;
  }
  .c-header-revamp-component .revamp-header-btn a {
    color: white;
    text-decoration: none;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    letter-spacing: 0.6px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) and (min-width: 1024px) {
  .c-header-revamp-component .revamp-header-btn {
    margin-right: 7px;
  }
  .lang-ar .c-header-revamp-component .revamp-header-btn {
    margin-left: 7px;
    margin-right: 0px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .hamburger-menu {
    height: 100vh;
    width: 100vw;
    background-color: #ffffff;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 11;
  }
  body:not(.lang-ar) .hamburger-menu.opening {
    animation: openLeftToRight-english 0.2s linear;
  }
  @keyframes openLeftToRight-english {
    from {
      left: -100%;
    }
    to {
      left: 0%;
    }
  }
  .lang-ar .hamburger-menu.opening {
    animation: openRightToLeft-english 0.2s linear;
  }
  @keyframes openRightToLeft-english {
    from {
      right: -100%;
    }
    to {
      right: 0%;
    }
  }
  body:not(.lang-ar) .hamburger-menu.has-sub-menu, body:not(.lang-ar) .hamburger-menu.closing, body:not(.lang-ar) .hamburger-menu.has-sub-menu.closing .slider-content.open, body:not(.lang-ar) .hamburger-menu.has-sub-menu.closing .search-box-slider.open {
    left: -100%;
    animation: closeRightToLeft-english 0.2s linear;
  }
  @keyframes closeRightToLeft-english {
    from {
      left: 0%;
    }
    to {
      left: -100%;
    }
  }
  .lang-ar .hamburger-menu.has-sub-menu, .lang-ar .hamburger-menu.closing, .lang-ar .hamburger-menu.has-sub-menu.closing .slider-content.open, .lang-ar .hamburger-menu.has-sub-menu.closing .search-box-slider.open {
    right: -100%;
    animation: closeLeftToRight-english 0.2s linear;
  }
  @keyframes closeLeftToRight-english {
    from {
      right: 0%;
    }
    to {
      right: -100%;
    }
  }
  .hamburger-menu .slider-name {
    width: 100%;
    display: flex;
    padding: 14px 20px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    align-self: stretch;
    color: #ffffff;
    font-feature-settings: "liga" off, "clig" off;
    /* English/Desktop/Heading/s */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
  .hamburger-menu .slider-name .profile-title {
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #0073CD;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: 0px;
    font-weight: 400;
  }
  .lang-ar .hamburger-menu .slider-name .profile-title {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .hamburger-menu .slider-name .profile-title svg {
    margin-top: 5px;
  }
  .lang-ar .hamburger-menu .slider-name .profile-title svg {
    transform: rotate(0deg);
  }
  .lang-ar .hamburger-menu .slider-name .profile-title {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
    font-weight: 700;
  }
  .hamburger-menu .slider-name .profile-title .profile-title svg {
    margin-top: 2px;
  }
  .hamburger-menu .slider-name .profile-title .non-loggedin-placeholder {
    margin-top: 5px;
    color: #000;
  }
  .lang-ar .hamburger-menu .slider-name {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
    letter-spacing: 0px;
    font-weight: 700;
  }
  .lang-ar .hamburger-menu .slider-name svg {
    transform: rotate(180deg);
  }
  .hamburger-menu .slider-name.profile-title {
    padding: 11px 20px 11px 16px;
  }
  .lang-ar .hamburger-menu .slider-name.profile-title {
    padding: 11px 20px 11px 19px;
  }
  .lang-ar .hamburger-menu .slider-name {
    font-weight: 700;
  }
  .hamburger-menu .slider-content {
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    position: fixed;
    z-index: 12;
    top: 0px;
    right: 0px;
  }
  body:not(.lang-ar) .hamburger-menu .slider-content.open {
    right: 0%;
    animation: openRightToLeft-english 0.2s linear;
  }
  @keyframes openRightToLeft-english {
    from {
      right: -100%;
    }
    to {
      right: 0%;
    }
  }
  .lang-ar .hamburger-menu .slider-content.open {
    left: 0%;
    right: auto;
    animation: openLeftToRight-english 0.2s linear;
  }
  @keyframes openLeftToRight-english {
    from {
      left: -100%;
    }
    to {
      left: 0%;
    }
  }
  .hamburger-menu .slider-content.open .slider-title .profile-title {
    font-weight: 700;
  }
  body:not(.lang-ar) .hamburger-menu .slider-content.close {
    right: -100%;
    animation: closeLeftToRight-english 0.2s linear;
  }
  @keyframes closeLeftToRight-english {
    from {
      right: 0%;
    }
    to {
      right: -100%;
    }
  }
  .lang-ar .hamburger-menu .slider-content.close {
    left: -100%;
    right: auto;
    animation: closeRightToLeft-english 0.2s linear;
  }
  @keyframes closeRightToLeft-english {
    from {
      left: 0%;
    }
    to {
      left: -100%;
    }
  }
  .hamburger-menu .slider-content .profile-slider {
    background-color: #F8F8F8;
  }
  .hamburger-menu .slider-content .slider-header {
    display: flex;
    height: 60px;
    padding: 12px 20px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    background-color: #F8F8F8;
  }
  .lang-ar .hamburger-menu .slider-content .slider-header span svg {
    transform: rotate(180deg);
  }
  .hamburger-menu .slider-content .slider-title {
    display: flex;
    padding: 13px 20px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Amaranth", "Open Sans", Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    border-bottom: 0.5px solid #D3D3D3;
    margin: 16px 0px 0px;
  }
  .lang-ar .hamburger-menu .slider-content .slider-title {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .hamburger-menu .slider-content .slider-title .profile-title {
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #0073CD;
    text-transform: capitalize;
    letter-spacing: 0px;
    font-weight: 400;
  }
  .lang-ar .hamburger-menu .slider-content .slider-title .profile-title {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .hamburger-menu .slider-content .slider-title .profile-title svg {
    margin-top: 2px;
  }
  .hamburger-menu .slider-content .slider-list li {
    display: flex;
    padding: 11px 40px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    align-self: stretch;
    color: #333333;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    /* 162.5% */
    letter-spacing: 0px;
    text-transform: capitalize;
  }
  .hamburger-menu .slider-content .slider-list li a {
    color: #000;
  }
  .hamburger-menu .slider-content .slider-list li:hover {
    background-color: #ffffff;
  }
  .lang-ar .hamburger-menu .slider-content .slider-list li {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .hamburger-menu .slider-content .slider-list li .helper-upcoming {
    display: flex;
    padding: 0px 4px 1px 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background-color: #f20000;
    color: white;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
  }
  .hamburger-menu .mb-nav-links-bottom {
    border-top: 1px solid #ffffff;
    background-color: #ffffff;
  }
  .hamburger-menu .mb-nav-links-bottom .mb-language-selector-container {
    color: #ffffff;
    letter-spacing: normal;
    line-height: 26px;
  }
  .hamburger-menu .mb-nav-links-bottom .mb-language-selector-container .language-selector-heading {
    color: #000;
  }
  .hamburger-menu .mb-nav-links-bottom .language-selector-capsule {
    border: 1px solid #0073cd;
    color: #000;
    text-transform: capitalize;
  }
  .hamburger-menu .mb-nav-links-bottom .language-selector-capsule.selected {
    color: #ffffff;
    background: #0073cd;
  }
  .hamburger-slider {
    background: linear-gradient(90deg, #FFF 0%, #EEF6FF 100%);
    padding-bottom: 16px;
  }
  .hamburger-slider .hamburger-slider-header {
    padding: 12px 20px;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
  }
  .hamburger-slider .hamburger-slider-header.opening-hour-div {
    padding: 14px 20px;
    width: 100%;
    background-color: inherit;
  }
  .lang-ar .hamburger-slider .hamburger-slider-header svg {
    transform: rotate(180deg);
  }
  .hamburger-slider .hamburger-slider-header .openinghours {
    width: 100%;
  }
  .hamburger-slider .hamburger-slider-header .openinghours .revamp-header-openinghours {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .hamburger-slider .hamburger-slider-header .openinghours .revamp-header-openinghours h4 {
    color: #404040;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .lang-ar .hamburger-slider .hamburger-slider-header .openinghours .revamp-header-openinghours h4 {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
    letter-spacing: 0px;
  }
  .hamburger-slider .hamburger-slider-header .searchBox {
    display: flex;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;
  }
  .hamburger-slider .hamburger-slider-header .searchBox p {
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    /* 162.5% */
    letter-spacing: 0px;
  }
  .lang-ar .hamburger-slider .hamburger-slider-header .searchBox p {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .lang-ar .hamburger-slider .hamburger-slider-header .searchBox p {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
    font-weight: 700;
  }
  .lang-ar .hamburger-slider .hamburger-slider-header .searchBox {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .lang-ar .hamburger-slider .hamburger-slider-header .searchBox svg {
    transform: rotate(0deg);
  }
  .hamburger-slider .hamburger-slider-header.searchBox {
    padding: 11px 16px;
    background-color: inherit;
  }
  .hamburger-slider .hamburger-slider-header picture {
    width: 85px;
    left: -10px;
    height: 100%;
  }
  .lang-ar .hamburger-slider .hamburger-slider-header picture {
    right: -10px;
    left: unset;
  }
  .hamburger-slider .search-box-slider {
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 12;
  }
  body:not(.lang-ar) .hamburger-slider .search-box-slider.open {
    right: 0%;
    animation: openRightToLeft-english 0.2s linear;
  }
  @keyframes openRightToLeft-english {
    from {
      right: -100%;
    }
    to {
      right: 0%;
    }
  }
  .lang-ar .hamburger-slider .search-box-slider.open {
    left: 0%;
    right: auto;
    animation: openLeftToRight-english 0.2s linear;
  }
  @keyframes openLeftToRight-english {
    from {
      left: -100%;
    }
    to {
      left: 0%;
    }
  }
  body:not(.lang-ar) .hamburger-slider .search-box-slider.close {
    right: -100%;
    animation: closeLeftToRight-english 0.2s linear;
  }
  @keyframes closeLeftToRight-english {
    from {
      right: 0%;
    }
    to {
      right: -100%;
    }
  }
  .lang-ar .hamburger-slider .search-box-slider.close {
    left: -100%;
    right: auto;
    animation: closeRightToLeft-english 0.2s linear;
  }
  @keyframes closeRightToLeft-english {
    from {
      left: 0%;
    }
    to {
      left: -100%;
    }
  }
  .hamburger-slider .search-box-slider .header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #F8F8F8;
  }
  .hamburger-slider .search-box-slider .header .search-header {
    display: flex;
    height: 60px;
    padding: 12px 20px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
  }
  .hamburger-slider .search-box-slider .header .search-header span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .lang-ar .hamburger-slider .search-box-slider .header .search-header span svg {
    transform: rotate(180deg);
  }
  .hamburger-slider .search-box-slider .header .search-input {
    display: flex;
    height: 48px;
    padding: 11px 20px;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-bottom: 0.5px solid #d3d3d3;
  }
  .hamburger-slider .search-box-slider .header .search-input input {
    background: transparent;
    width: 100%;
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0px;
  }
  .lang-ar .hamburger-slider .search-box-slider .header .search-input input {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .hamburger-slider .search-box-slider .header .search-input input::placeholder {
    color: #000;
  }
  .hamburger-slider .search-box-slider .header .search-input svg path {
    stroke: #ffffff;
  }
  .hamburger-slider .search-box-slider .popular-search {
    gap: 0px;
    padding-top: 16px;
  }
  .hamburger-slider .search-box-slider .popular-search.suggestion {
    background-color: #EEF6FF;
    color: #000;
    height: fit-content;
  }
  .hamburger-slider .search-box-slider .popular-search.suggestion .search-list {
    height: fit-content;
  }
  .hamburger-slider .search-box-slider .popular-search.suggestion .search-list .list-item {
    cursor: pointer;
  }
  .hamburger-slider .search-box-slider .popular-search.suggestion .search-list .list-item .list-item-box svg path {
    stroke: #000;
  }
  .hamburger-slider .search-box-slider .popular-search.suggestion .search-list .list-item .list-item-text {
    color: #000;
    font-weight: 400;
  }
  .hamburger-slider .search-box-slider .popular-search .popular-title {
    display: flex;
    height: 48px;
    padding: 11px 40px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    color: #000;
  }
  .hamburger-slider .search-box-slider .popular-search .popular-title svg {
    margin-top: 5px;
  }
  .lang-ar .hamburger-slider .search-box-slider .popular-search .popular-title {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .hamburger-slider .search-box-slider .popular-search .search-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    align-self: stretch;
    height: fit-content;
  }
  .hamburger-slider .search-box-slider .popular-search .search-list .list-item {
    display: flex;
    height: 48px;
    padding: 11px 40px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
  .hamburger-slider .search-box-slider .popular-search .search-list .list-item .list-item-box {
    color: #ffffff;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0px;
  }
  .lang-ar .hamburger-slider .search-box-slider .popular-search .search-list .list-item .list-item-box {
    transform: rotate(180deg);
  }
  .hamburger-slider .search-box-slider .popular-search .search-list .list-item .list-item-box svg path {
    stroke: #ffffff;
  }
  .hamburger-slider .search-box-slider .popular-search .search-list .list-item .list-item-text {
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
  }
  .lang-ar .hamburger-slider .search-box-slider .popular-search .search-list .list-item .list-item-text {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .lang-ar .hamburger-slider .search-box-slider .popular-search .search-list .list-item .list-item-text {
    font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  }
  .navlinkDiv {
    margin-top: 16px;
    margin-bottom: 16px;
    /* < 1023px */
  }
}
@media (min-width: 320px) and (max-width: 767px) and (min-width: 320px) and (max-width: 1024px) {
  .navlinkDiv {
    overflow-y: scroll;
    max-height: 45vh;
    background-color: #ffffff;
    margin: 0;
  }
  .navlinkDiv .revamp-header-nav-wrapper {
    list-style-type: none; /* Removes bullet points */
    margin: 0; /* Removes default margin */
    padding: 0; /* Removes default padding */
  }
  .navlinkDiv .revamp-header-nav-wrapper .revamp-header-nav-wrapper-list .slider-name {
    color: #000;
    font-family: "Amaranth", "Open Sans", Arial, sans-serif;
    font-size: 14px;
    font-weight: 700;
  }
}
.revamp-header-notice-component.mobile {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .revamp-header-notice-component.mobile {
    display: none;
  }
}

.header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container.revampheader-empty-cart-container .empty-title {
  padding: 24px 24px 12px;
  font-weight: 700;
}
.header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container.revampheader-empty-cart-container .btn-primary {
  margin: 0px 24px 24px;
  width: auto;
}
.header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container.revampheader-empty-cart-container .btn-primary a {
  -webkit-border-top-left-radius: 18px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 18px;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 18px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 18px;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 18px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  display: inline-block;
  margin-bottom: 0;
  padding: 10px 30px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #0073cd;
  color: #ffffff;
  border: 2px solid #0073cd;
  /* Medium devices (desktops, 1024px and up) */
  width: 100%;
}
@media (min-width: 768px) {
  .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container.revampheader-empty-cart-container .btn-primary a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container.revampheader-empty-cart-container .btn-primary a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container.revampheader-empty-cart-container .btn-primary a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container.revampheader-empty-cart-container .btn-primary a:focus {
  -webkit-border-top-left-radius: 18px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 18px;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 18px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 18px;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 18px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 0;
}
@media only screen and (min-width: 1024px) {
  .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container.revampheader-empty-cart-container .btn-primary a:hover, .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container.revampheader-empty-cart-container .btn-primary a:focus {
    background-color: #ffffff;
    color: #0073cd;
    border: 2px solid #0073cd;
  }
}
.header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container.revampheader-empty-cart-container .btn-primary a.disabled, .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container.revampheader-empty-cart-container .btn-primary a[disabled], fieldset[disabled] .header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container.revampheader-empty-cart-container .btn-primary a {
  background-color: #a4a4a4;
  color: #ffffff;
  border: 2px solid #a4a4a4;
}
.header-miniCart .b2c-mini-cart-wrapper .c-b2c-mini-cart .c-b2c-cart .c-b2c-cart-wrapper .empty-cart-container.revampheader-empty-cart-container .btn-primary .btn-icon {
  display: none;
}

.c-b2c-mini-cart .c-b2c-cart.headerRevamp .c-b2c-cart-content .cart-summary-container {
  background-color: #eef6ff;
}
.c-b2c-mini-cart .c-b2c-cart.headerRevamp .c-b2c-cart-content .cart-summary-container .cart-summary-details .cart-price-container .total-price-container {
  background-color: inherit;
}
.c-b2c-mini-cart .c-b2c-cart.headerRevamp .c-b2c-cart-content .cart-summary-container .cart-summary-details .cart-price-container .total-price-container .final-price-container .cart-total-label, .c-b2c-mini-cart .c-b2c-cart.headerRevamp .c-b2c-cart-content .cart-summary-container .cart-summary-details .cart-price-container .total-price-container .final-price-container .cart-total-price {
  color: black;
}
.c-b2c-mini-cart .c-b2c-cart.headerRevamp .c-b2c-cart-content .ticket-section {
  display: block;
}

.cart-dropdown-wrap.headerRevamp.hasOrder.showCart {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .cart-dropdown-wrap.headerRevamp.hasOrder.showCart .header-miniCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-cart.headerRevamp {
    height: inherit;
  }
  .cart-dropdown-wrap.headerRevamp.hasOrder.showCart .header-miniCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-cart.headerRevamp .mycart-heading-revamp-header {
    background-color: #F8F8F8;
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    align-items: center;
    position: relative;
  }
  .cart-dropdown-wrap.headerRevamp.hasOrder.showCart .header-miniCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-cart.headerRevamp .mycart-heading-revamp-header .mycart-heading {
    color: #000;
    line-height: 26px;
    letter-spacing: normal;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 400;
    font-weight: 700;
    font-size: 16px;
  }
  .cart-dropdown-wrap.headerRevamp.hasOrder.showCart .header-miniCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-cart.headerRevamp .mycart-heading-revamp-header .c-mini-check-icon {
    position: absolute;
    margin-right: 0px;
    width: 18px;
    height: 18px;
    transform: translate(0%, -50%);
    top: 50%;
    right: 25px;
    left: auto;
    /* < 1023px */
  }
}
@media (min-width: 320px) and (max-width: 1024px) and (min-width: 320px) and (max-width: 1024px) {
  .cart-dropdown-wrap.headerRevamp.hasOrder.showCart .header-miniCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-cart.headerRevamp .mycart-heading-revamp-header .c-mini-check-icon {
    background-image: url(../../../assets/img/ChevronUp.png);
    transform: translate(0px, -50%) rotate(180deg) !important;
    width: 20px;
    height: 25px;
  }
}
@media (min-width: 320px) and (max-width: 1024px) {
  .cart-dropdown-wrap.headerRevamp.hasOrder.showCart .header-miniCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-cart.headerRevamp .c-b2c-cart-wrapper {
    height: inherit;
  }
  .cart-dropdown-wrap.headerRevamp.hasOrder.showCart .header-miniCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-cart.headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content {
    height: inherit;
    justify-content: space-between;
    flex-direction: column;
  }
  .cart-dropdown-wrap.headerRevamp.hasOrder.showCart .header-miniCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-cart.headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .minicart-scroll-wrapper {
    max-height: calc(100vh - 306px);
  }
  .cart-dropdown-wrap.headerRevamp.hasOrder.showCart .header-miniCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-cart.headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container {
    padding: 32px 24px;
    position: sticky;
  }
  .cart-dropdown-wrap.headerRevamp.hasOrder.showCart .header-miniCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-cart.headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .cart-summary-details .cart-price-container {
    margin: 0;
  }
  .cart-dropdown-wrap.headerRevamp.hasOrder.showCart .header-miniCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-cart.headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .cart-summary-details .cart-price-container .total-price-container .final-price-container .cart-total-price {
    padding: 0;
  }
  .cart-dropdown-wrap.headerRevamp.hasOrder.showCart .header-miniCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-cart.headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container {
    margin-top: 0;
    margin-bottom: 0;
  }
  .cart-dropdown-wrap.headerRevamp.hasOrder.showCart .header-miniCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-cart.headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .cart-summary-container {
    padding: 0;
  }
  .cart-dropdown-wrap.headerRevamp.hasOrder.showCart .header-miniCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-cart.headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .c-my-cart-b2c-container .cart-summary-container .checkout-button .btn-primary .form-wrapper-buttons-anchor {
    text-transform: uppercase;
    font-weight: 700;
  }
  .cart-dropdown-wrap.headerRevamp.hasOrder.showCart .header-miniCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-cart.headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .continue-shopping-cta {
    padding: 0;
  }
  .cart-dropdown-wrap.headerRevamp.hasOrder.showCart .header-miniCart .header-miniCart .b2c-mini-cart-wrapper .c-b2c-cart.headerRevamp .c-b2c-cart-wrapper .c-b2c-cart-content .cart-summary-container .cart-summary .continue-shopping-cta .continue-shopping-cta-text {
    color: #0073CD;
    border-bottom: 2px solid #0073CD;
    font-weight: 800;
    line-height: 18px;
  }
}

.c-header-revamp-component .container {
  width: 100vw;
  height: 336px;
  padding: 32px 10px;
  background: #EEF6FF;
  box-shadow: 0px 1px 0px #d3d3d3;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 35px;
  left: 0px;
  z-index: 100;
}
.c-header-revamp-component .relative-box {
  width: 24px;
  height: 24px;
  position: relative;
}
.c-header-revamp-component .relative-box-inner {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0.69px;
  top: 12px;
  transform: rotate(-45deg);
  transform-origin: 0 0;
  border: 1.5px solid #6E6E6E;
}
.c-header-revamp-component .content {
  height: 272px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
}
.c-header-revamp-component .image-container {
  width: 672px;
  height: 52px;
  border: 1px solid #d3d3d3;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  gap: 8px;
}
.c-header-revamp-component .image-container svg {
  width: fit-content;
}
.c-header-revamp-component .image-container input {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  align-items: center;
  width: 100%;
  background-color: transparent;
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  letter-spacing: 0px;
}
.lang-ar .c-header-revamp-component .image-container input {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.lang-ar .c-header-revamp-component .image-container input {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-header-revamp-component .image-container .autocomplete-wrapper {
  width: 100%;
}
.c-header-revamp-component .image-container .autocomplete-wrapper form {
  border: none;
}
.c-header-revamp-component .image-container .autocomplete-wrapper form .btn-reset {
  right: 0;
  position: absolute;
  padding-left: 0px;
  padding-right: 0px;
  top: 5px;
}
.c-header-revamp-component .image-container .autocomplete-wrapper form .btn-reset svg {
  width: 12px;
  height: 12px;
  fill: #f20000;
}
.c-header-revamp-component .image-container .autocomplete-wrapper form .search-trigger {
  display: none;
}
.c-header-revamp-component .image-container.searchEnabled {
  border: 1px solid #333;
}
.c-header-revamp-component .image {
  width: 672px;
  height: 52px;
  position: absolute;
  border: 1px solid #d3d3d3;
}
.c-header-revamp-component .search-bar {
  position: absolute;
  left: 30px;
  top: 13px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.c-header-revamp-component .search-box {
  width: 24px;
  height: 24px;
  position: relative;
}
.c-header-revamp-component .search-box-inner {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 3.9px;
  top: 3.74px;
  border: 1.5px solid #6E6E6E;
}
.c-header-revamp-component .search-text {
  color: #6E6E6E;
  font-size: 16px;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-weight: 700;
  line-height: 26px;
  word-wrap: break-word;
}
.c-header-revamp-component .popular-search {
  align-self: stretch;
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
}
.c-header-revamp-component .popular-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  align-self: stretch;
  color: #6E6E6E;
  word-wrap: break-word;
  letter-spacing: 0px;
}
.lang-ar .c-header-revamp-component .popular-title {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.lang-ar .c-header-revamp-component .popular-title {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-header-revamp-component .search-list {
  align-self: stretch;
  height: 152px;
  padding-left: 1px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
}
.lang-ar .c-header-revamp-component .search-list {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-header-revamp-component .search-list.popular-search-term .list-item .list-item-text {
  font-weight: 700;
  color: #333333;
}
.c-header-revamp-component .list-item {
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.c-header-revamp-component .list-item-box {
  width: 20px;
  height: 20px;
  position: relative;
}
.lang-ar .c-header-revamp-component .list-item-box {
  transform: rotate(180deg);
}
.c-header-revamp-component .list-item-box-inner {
  width: 13.33px;
  height: 10.67px;
  position: absolute;
  left: 3.33px;
  top: 4.67px;
  border: 1.5px solid #ffffff;
}
.c-header-revamp-component .list-item-text {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  color: #ffffff;
  word-wrap: break-word;
  font-feature-settings: "liga" off, "clig" off;
  font-style: normal;
  letter-spacing: 0px;
}
.lang-ar .c-header-revamp-component .list-item-text {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.lang-ar .c-header-revamp-component .list-item-text {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
.c-header-revamp-component .search-close {
  position: absolute;
  right: 36px;
  width: fit-content;
  top: 20.4px;
  cursor: pointer;
  width: 24px;
  height: 25px;
}
.lang-ar .c-header-revamp-component .search-close {
  left: 36px;
  right: auto;
}
.c-header-revamp-component .content .image-container svg {
  width: 25px;
  height: 24px;
  cursor: pointer;
}
.c-header-revamp-component .content .image-container .search-icon-desktop-wrap.enableSearch {
  cursor: pointer;
}
.c-header-revamp-component .search-component-icon {
  cursor: pointer;
}
.c-header-revamp-component .search-component-icon svg {
  width: 24px;
  height: 24px;
}
.c-header-revamp-component .search-component .container {
  background-color: #F8F8F8;
}
.c-header-revamp-component .search-component .container .search-close {
  cursor: pointer;
}
.c-header-revamp-component .search-component .container .content .image-container .search-close-button {
  cursor: pointer;
}
.c-header-revamp-component .clear-search-button {
  cursor: pointer;
}
.c-header-revamp-component .results-container {
  transform: skew(17deg);
}
.c-header-revamp-component .results-container .autosuggest-list {
  margin-top: 30px;
  background-color: #EEF6FF;
}

/*
 * Config
 * @ Variables
 *
*/
/*calendar dropdown*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  YWW base Font size
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * H8
*/
/*
 * H9
*/
/*
 * H10
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
* form placeholder variables
*/
/*
 * Base
 * @ Colors
 *
*/
/* Colors */
/* Trip Advisor Color */
/* Guest checkout Color */
/*
 * Line height font
 */
/*
 * Border Radius
 */
/*
 * Border Radius Separate
 */
/*
 * Box Shadow
 */
/*
 * Widescreen
 */
/*
 * Desktop (PC's)
 */
/*
 * Wide screen Desktop (PC's)
 */
/*
 * Extra Wide screen Desktop (PC's)
 */
/*
 * Tablet
 */
/*
  * TabletOnly
  */
/*
 * upto Tablet
 */
/*
 * Tablet2
 */
/*
 * Mobile
 */
/*
 * No Screen
 */
/*
 * Transition
    usage example
      a{
      color: gray;
      @include transition(color .3s ease);
      &:hover {
        color: black;
      }
}
 */
/*
 * Opacity
 */
/*
 * Clearfix
 */
/*
 * Configure vendor
 */
/*
 * SVG stroke and fill
 */
/*
 * Transform
 */
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
 * Flex
 */
/*
 * New Design System - 2025
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
/*
 * Base
 * @ Gradients
 *
*/
/*
 * Line height font
 */
.tenant-yww .email-verification__cookie {
  padding: 14px 115px;
  z-index: 9999;
  background: #EEF6FF;
  width: 100%;
  display: flex;
  align-items: center;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-yww .email-verification__cookie {
    padding: 16px 17px;
  }
}
.tenant-yww .email-verification__cookie__content {
  display: flex;
  align-items: center;
  flex: 1;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-yww .email-verification__cookie__content {
    align-items: flex-start;
    flex-direction: column;
  }
}
.tenant-yww .email-verification__cookie__content__description {
  color: #333333;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 16px;
  letter-spacing: normal;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  flex: 1;
}
.tenant-yww .email-verification__cookie__content__action a {
  color: #0073cd;
  padding-bottom: 1px;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  cursor: pointer;
  margin-left: 24px;
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .tenant-yww .email-verification__cookie__content__action a {
    margin-left: 0;
    margin-top: 16px;
  }
}
.tenant-yww .email-verification__cookie__close {
  height: 12px;
  width: 12px;
  margin-left: 48px;
}
.tenant-yww .c-cookies {
  background-color: #ffffff;
  color: #333;
  padding: 7px 20px;
  text-align: center;
  margin: auto;
  opacity: 0.95;
  position: fixed;
  bottom: 56px;
  width: 100%;
  z-index: 1002;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
.tenant-yww .c-cookies.revamp-header-notice-component {
  position: relative;
  bottom: auto;
  display: flex;
  padding: 12px 35px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  /* < 1023px */
}
.tenant-yww .c-cookies.revamp-header-notice-component span svg {
  width: 25px;
  height: 24px;
}
.tenant-yww .c-cookies.revamp-header-notice-component p {
  color: #000;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .tenant-yww .c-cookies.revamp-header-notice-component p {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
.lang-ar .tenant-yww .c-cookies.revamp-header-notice-component p {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  text-align: right;
}
.tenant-yww .c-cookies.revamp-header-notice-component p a {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}
.tenant-yww .c-cookies.revamp-header-notice-component p a:hover {
  color: inherit;
}
@media (min-width: 320px) and (max-width: 1024px) {
  .tenant-yww .c-cookies.revamp-header-notice-component {
    padding: 12px 20px;
  }
}
.tenant-yww .c-cookies.revamp-header-notice-component .revamp-header-note {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 8px;
}
.tenant-yww .c-cookies.revamp-header-notice-component .close-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.purchase-cookie .tenant-yww .c-cookies {
  bottom: 0;
}
.purchase-cookie .tenant-yww .c-cookies-minicart {
  /* < 768px */
}
@media (min-width: 320px) and (max-width: 767px) {
  .purchase-cookie .tenant-yww .c-cookies-minicart {
    bottom: 82px;
    z-index: 1099;
  }
}
.purchase-cookie .tenant-yww .c-cookies-step-2-cta-sticky {
  bottom: 60px;
  /* Medium devices (desktops, 1024px and up) */
}
.purchase-cookie .tenant-yww .c-cookies-step-2-cta-sticky.confirmation-error {
  bottom: 0;
}
@media only screen and (min-width: 1024px) {
  .purchase-cookie .tenant-yww .c-cookies-step-2-cta-sticky {
    bottom: 0;
  }
}
@media (min-width: 768px) {
  .tenant-yww .c-cookies {
    padding: 10px 22px;
    bottom: 51px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-cookies {
    padding: 20px 36px;
    bottom: 0;
  }
}
.tenant-yww .c-cookies .w--content {
  margin: 0;
  max-width: 100%;
  text-align: left;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-cookies .w--content {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-cookies .w--content {
    position: relative;
  }
}
.tenant-yww .c-cookies .cookie-description {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.71;
  letter-spacing: 0.7px;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 300;
  margin-bottom: 10px;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 12px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-cookies .cookie-description {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-cookies .cookie-description {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-cookies .cookie-description {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media (min-width: 768px) {
  .tenant-yww .c-cookies .cookie-description {
    width: 97.311827957%;
    max-width: 996.4731182796px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    font-size: 12px;
    line-height: 18px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-cookies .cookie-description {
    width: 81.5151515152%;
    max-width: 1113.496969697px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    font-size: 12px;
    line-height: 14px;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-cookies .cookie-description {
    max-width: none;
  }
}
.tenant-yww .c-cookies .cookie-description a {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.71;
  letter-spacing: 0.7px;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  text-align: left;
  color: #ffffff;
  /* Medium devices (desktops, 1024px and up) */
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.71;
  letter-spacing: 0.7px;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 300;
  color: #333333;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 16px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-cookies .cookie-description a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-cookies .cookie-description a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-cookies .cookie-description a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-cookies .cookie-description a:hover, .tenant-yww .c-cookies .cookie-description a:focus {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .tenant-yww .c-cookies .cookie-description a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-cookies .cookie-description a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-cookies .cookie-description a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media (min-width: 768px) {
  .tenant-yww .c-cookies .cookie-description a {
    font-size: 12px;
    line-height: 19.2px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-cookies .cookie-description a {
    line-height: 16px;
    font-size: 10px;
  }
}
.tenant-yww .c-cookies .cookie-accept {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1.2px;
  line-height: 1.22;
  font-size: 18px;
  font-size: 1.125rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  text-align: left;
  color: #ffffff;
  text-decoration: underline;
  /* Medium devices (desktops, 1024px and up) */
  text-decoration: underline;
  cursor: pointer;
  color: #000000;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 14px;
  text-align: center;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-cookies .cookie-accept {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-cookies .cookie-accept {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-cookies .cookie-accept {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media (min-width: 768px) {
  .tenant-yww .c-cookies .cookie-accept {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-cookies .cookie-accept {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-cookies .cookie-accept {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-cookies .cookie-accept:hover, .tenant-yww .c-cookies .cookie-accept:focus {
    border: none;
    text-decoration: none;
  }
}
.tenant-yww .c-cookies .cookie-accept:hover {
  text-decoration: none;
}
@media (min-width: 768px) {
  .tenant-yww .c-cookies .cookie-accept {
    width: 13.9784946237%;
    max-width: 143.1397849462px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    padding-right: 0;
    font-size: 13px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-cookies .cookie-accept {
    width: 9.811827957%;
    max-width: 100.4731182796px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    padding: 2px;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .c-cookies .cookie-accept {
    right: 36px;
    position: absolute;
    top: auto;
  }
  .lang-ar .tenant-yww .c-cookies .cookie-accept {
    left: 36px;
    right: auto;
  }
}
.tenant-yww .c-cookies .cookie-title, .tenant-yww .c-cookies .cookie-description, .tenant-yww .c-cookies .cookie-accept {
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-cookies .cookie-title, .tenant-yww .c-cookies .cookie-description, .tenant-yww .c-cookies .cookie-accept {
    margin-bottom: 0;
  }
}
.tenant-yww .c-cookies .cookie-close-icon {
  top: 5px;
  right: 5px;
  width: 10px;
  height: 10px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .c-cookies .cookie-close-icon {
    position: relative;
    top: auto;
    right: 0;
    margin-left: 0;
    width: 15px;
    height: 15px;
  }
  .tenant-yww .c-cookies .cookie-close-icon:before {
    content: "";
    height: 12px;
    border: solid 1px #333;
    position: absolute;
    top: 2px;
    left: -13px;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .c-cookies .cookie-close-icon:before {
    left: -30px;
  }
}
.tenant-yww .c-cookies .cookie-close-icon svg.icon {
  stroke: #333;
}

.c-header-revamp-component {
  /* < 1023px */
}
.c-header-revamp-component.desktop {
  display: block;
}
.c-header-revamp-component.mobile {
  display: none;
}
@media (min-width: 320px) and (max-width: 1024px) {
  .c-header-revamp-component.mobile {
    display: flex;
  }
  .c-header-revamp-component.desktop {
    display: none;
  }
}